import React from "react";
import Account from '../modules/auth/screen/account.screen';
import Toast from "../component/toast/toast";
//import "../assets/css/style.css";
interface Props {
  t: any;
  history: any;
  showOTPModal: boolean;
  toggleOTPModal: Function;
}

function higherOrderComponent(WrappedComponent: any) {
  class HOCC extends React.Component<Props, any> {
    constructor(props: Props) {
      super(props);
    }
    render() {
      return (
        <Account>
          {/* <Toast /> */}
          <WrappedComponent {...this.props} />
        </Account>
      );
    }
  }

  return HOCC;
};
export default higherOrderComponent;