import React from "react";
import LayoutWrapper from "../modules/layoutwrapper/layoutwrapper";
//import "../assets/sass/authlayout.scss";
//import "../assets/css/style.css";

interface Props {
  history: any;
  location: any;
  match: any;
  doLogout: Function;
}

function higherOrderComponent(WrappedComponent: any) {
  class HOCC extends React.Component<Props, any> {
    componentDidMount() { }

    render() {
      return (
        <LayoutWrapper
          history={this.props.history}
          doLogout={this.props.doLogout}
        >
          <WrappedComponent
            history={this.props.history}
            match={this.props.match}
            location={this.props.location}
          />
        </LayoutWrapper>
      );
    }
  }

  return HOCC;
}
export default higherOrderComponent;
