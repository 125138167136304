import React, { FunctionComponent, useEffect, useState } from "react";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../apollo/client";
//import { Right } from "react-bootstrap/lib/Media";
import { useTranslation } from "react-i18next";
import en from '../../languages/en/en.json'
import fr from '../../languages/fr/fr.json'

interface Props { }
interface State {
    emailFooter: string;
 }

const Footer: FunctionComponent = (State) => {
    const [venci, setVenci] = useState("");
    const [femail,setFemail]= useState("");
    // const [lang,setLang]=useState("en");
    const [logoutConfirmation, setLogoutConfirmation] = useState(false),
        //{ t, i18n } = useTranslation(),
        userName = localStorage.getItem("username"),
        recruiterPic = localStorage.getItem("recruiterPic"),
        userid = localStorage.getItem("userId");
    // localStorage.setItem("languageSelected", 'en');
    useEffect(() => {
        let language = window.localStorage.getItem('lang')
        //@ts-ignore
        setLang(language)
     

        // if(isLoggedIn === true && user && user.uid) {
        //     navigateUser(user);
        // }
    }, []);

    const { t, i18n } = useTranslation();
    const [lang, setLang] = useState('en')
    useEffect(() => {
        document.querySelectorAll('.lang_option')
            .forEach(item => {
                item.addEventListener('click', event => {
                    if (item) {
                        console.log('footer', item.innerHTML.toLocaleLowerCase())
                        setLang(item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr");
                    }
                });
            })
    }, [])
    return (
        <footer>
            <ApolloProvider client={apolloClient}>
                <React.Fragment>
                    <div className="container">
                        <div className="row foot">
                            <div className="col-lg-4">
                                <h3>{lang=="en"?en.op25:fr.op25}</h3>
                                <div className="social-media">
                                    <a href="#" target='blank'> <i className="fab fa-facebook" aria-hidden="true"></i></a>
                                    <a href="#"> <i className="fab fa-youtube" aria-hidden="true"></i></a>
                                    {/* <a href="#">  <i className="fab fa-instagram" aria-hidden="true"></i></a> */}
                                    {/* <a href="#">  <i className="fab fa-twitter" aria-hidden="true"></i></a> */}
                                    {/* <a href="#">  <i className="fab fa-tiktok"></i></a> */}
                                    <a href="https://wa.me/+15148024572" target='blank'>  <i className="fab fa-whatsapp" aria-hidden="true"></i></a>
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="footer-nav">
                                    <ul style={{listStyle: "none"}}>
                                        <li><a href="/#/home">{lang=="en"?en.op26:fr.op26}</a></li>
                                        <li><a href="/#/whoweare">{lang=="en"?en.op27:fr.op27} </a></li>
                                        {/* <li><a href="#"> {data.data.footers.data[0].attributes.op3} </a> </li> */}
                                        {/* <li><a href="#"> {data.data.footers.data[0].attributes.op4}</a> </li>  */}

                                        <li><a href="/#/trainings">{lang=="en"?en.op28:fr.op28}</a> </li>
                                        <li><a href="/#/contactus">{lang=="en"?en.op40:fr.op40}</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2">

                            </div>

                            <div className="col-lg-4">
                                <form name="newsletter" id="contactForm" >
                                    <h3>{lang=="en"?en.op30:fr.op30}</h3>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="email" className="form-control" placeholder={lang=="en"?en.op31:fr.op31} id="email" required data-validation-required-message="Please enter your email address." onChange={(e:any) => setFemail(e.target.value)} />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div id="success"></div>
                                            <button type="button" className="btn" onClick={(e:any) => {
                                            window.localStorage.setItem("femail", femail)
                                            console.log(femail)
                                            window.location.href = '/#/register'
                                            }
                                            }>{lang=="en"?en.op35:fr.op35}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className="row">
                            <div className="col-lg-4">
                                {/* © {data.data.footers.data[0].attributes.cc} <br/>
                <a href="#"> {data.data.footers.data[0].attributes.op7}</a><a href="#"> {data.data.footers.data[0].attributes.op8}</a> */}
                            </div>
                            <div className="col-lg-4">

                            </div>

                            <div className="col-lg-4">
                                © All rights reserved – 2022 Le Bled<br />
                                <a href="#">{lang=="en"?en.op33:fr.op33} </a><a href="#"> {lang=="en"?en.op34:fr.op34}</a>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            </ApolloProvider>

        </footer>

    );
};

export default Footer;