import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
import { GetNFTs } from "..";
import { NFTModal } from "../../../modal";
import web3Utills from '../../../utils/web3Utills';
import { UpdateNFT, UpdateNFTBuyNow } from "..";

const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
    GetNFTs?: any;
    UpdateNFT?: any;
    UpdateNFTBuyNow?: any;
    nftsList: any;
    updateNFTResult?: any;
}

interface State {
lang:string;
training:string;
}

export class FaqScreen extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
           lang:"en",
           training:"Training"
        };
      
    }

    async componentDidMount() {

        //@ts-ignore
window.renderCarousal();
let language=window.localStorage.getItem('lang')
//@ts-ignore
this.setState({lang:language})
document.querySelectorAll('.lang_option')
.forEach(item => {
  item.addEventListener('click', event => {
    if(item){
        console.log(item.innerHTML.toLocaleLowerCase())
      this.setState({lang:item.innerHTML.toLocaleLowerCase()=="english"?"en":"fr"});
    }
  });
})

    }

    render() {

        return (
           <>
            <ApolloProvider client={apolloClient}>
              <React.Fragment>
                  {/* data.data.theories.data[0].attributes */}
              {/* <Query query={gql`
query Theory{
    theories(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading1
                heading2
                heading3
                subheading1
                subheading2
                subheading3
                subheading4
                subheading5
                subheading6
                subheading7
                subheading8
                subheading9
                url1
                url2
                url3
                description1
                description2
                description3
                description4
                description5
                description6
                description7
                description8
                description9

            }
        }
     
    }
    

  }`} >
                                    {
                                       
                                        //@ts-ignore
                                    (data:any)=>{
                                      console.log('data',data)
                                        return <div>
                                            {
                                            
                                                data.data?(<>
                                                       <section className="page">

<div className="container">
    <div className="row">
        <div className="col-md-8">
            <h2> {this.state.lang=="en"?en.faq:fr.faq}</h2>
            <h3>{data.data.theories.data[0].attributes.subheading1}</h3>
            <p>{data.data.theories.data[0].attributes.description1}</p>

            <div className="row">
                <div className="col-md-6">
                    <h4>{data.data.theories.data[0].attributes.subheading2}</h4>
                    <p>{data.data.theories.data[0].attributes.description2}</p>
                </div>
                <div className="col-md-6">
                    <h4>{data.data.theories.data[0].attributes.subheading3}</h4>
                    <p>{data.data.theories.data[0].attributes.description3}</p>
                </div>

            </div>        



        </div>

        <div className="col-md-4"><img src={data.data.theories.data[0].attributes.url1} className="img-responsive" alt="" /></div>
    </div>


    <div className="row">
        <div className="col-md-4"><img src={data.data.theories.data[0].attributes.url2} className="img-responsive" alt=""/></div>
        <div className="col-md-8">
            <h2>{data.data.theories.data[0].attributes.heading2}                </h2>
            <h3>{data.data.theories.data[0].attributes.subheading4}         </h3>
            <p>{data.data.theories.data[0].attributes.description4}
            </p>

            <div className="row">
                <div className="col-md-6">
                    <h4>{data.data.theories.data[0].attributes.subheading5}</h4>
                    <p>{data.data.theories.data[0].attributes.description5}</p>
                </div>
                <div className="col-md-6">
                    <h4>{data.data.theories.data[0].attributes.subheading6}                        </h4>
                    <p>{data.data.theories.data[0].attributes.description6}                    </p>
                </div>

            </div>        



        </div>

      
    </div>


    <div className="row">
        <div className="col-md-8">
            <h2> {data.data.theories.data[0].attributes.heading3} </h2>
            <h3>{data.data.theories.data[0].attributes.subheading7}               </h3>
            <p>{data.data.theories.data[0].attributes.description7}
            </p>

            <div className="row">
                <div className="col-md-6">
                    <h4>{data.data.theories.data[0].attributes.subheading8}                        </h4>
                    <p>{data.data.theories.data[0].attributes.description8}                       </p>
                </div>
                <div className="col-md-6">
                    <h4>{data.data.theories.data[0].attributes.subheading9}                    </h4>
                    <p>{data.data.theories.data[0].attributes.description9}                     </p>
                </div>

            </div>        



        </div>

        <div className="col-md-4"><img src={data.data.theories.data[0].attributes.url3} className="img-responsive" alt="" /></div>
    </div>

</div>    

</section> 					 
                      </>):(<></>)
                                                  
                                                
                                           
                                            }
                                   
                                        </div>
                                    }
                                    }
                                      </Query>	 */}
           
            <p id="back-top">
                <a onClick={() => {window.scrollTo(0, 0)}}><i className="fa fa-angle-up"></i></a>
            </p>
            </React.Fragment>  
            </ApolloProvider>
         </> 
        );
    }
}

function mapStateToProps(state: any) {

    return {
        nftsList: state.homeReducer || {},
        updateNFTResult: state.UpdateNFTReducer || {},
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        GetNFTs: (payload: any, callback: Function) => dispatch(GetNFTs(payload, callback)),
        UpdateNFT: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFT(payload, token, callback)),
        UpdateNFTBuyNow: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFTBuyNow(payload, token, callback)),
    }
}

export const FaqModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(FaqScreen)
);

export const Faq = AppLayout(FaqModule);
