import React, { PureComponent } from "react";
import OnBoardingBanner from "../component/onboardingleft/onboardingbanner";
import Applayout from "../../../applayout/applayout";
import Utils from "../../../utils";
import { doForgotPassword } from "../";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";
import { NavLink } from "react-router-dom";
import i18n from "i18next";

interface Props {
  t: any;
  history?: any;
  doForgotPassword: Function;
}
interface State {
  email: string;
  isLoading: boolean;
  popupMessage: string;
  popupStatus: string;
  popupTitle: string;
  error: {
    emailError: string;
  };
  confirmPasswordForgetModal: boolean;
  emailAdded: string;
}
class ForgotScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      isLoading: false,
      popupMessage: "",
      popupStatus: "loading",
      popupTitle: "Status",
      error: {
        emailError: "",
      },
      confirmPasswordForgetModal: false,
      emailAdded: "",
    };
  }
  ShowErrorMessage(isShowLoading: boolean, message: string, status: string, transactionHash: any, title: string) {
    try {
      this.setState({
        isLoading: isShowLoading,
        popupTitle: title,
        popupStatus: status,
        popupMessage: message,
      });
    } catch (ex) {
      console.log(ex);
    }
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };
  selectLang = (language: string) => {
    Utils.constant.axios.defaults.headers.lng = language;
    i18n.changeLanguage(language);
  };
  hidePasswordForgetModal = () => {
    this.setState({ confirmPasswordForgetModal: false });
    this.props.history.push("/");
  };
  handleSubmit = () => {
    let { email, error } = this.state;
    let formError = Utils.constant.validateForm({ email, error });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) });
    if (isValid) {
      let payload = {
        email,
        user_type: Utils.constant.userType.recruiter,
      };
      this.setState({ emailAdded: email });
      this.props.doForgotPassword(payload, (response: any) => {

        this.setState({ confirmPasswordForgetModal: true });
        this.ShowErrorMessage(true, "Recovery link sent to your email", "success", "", "Sent");

      }, (error: any) => {
        this.setState({ confirmPasswordForgetModal: true });
        this.ShowErrorMessage(true, error, "error", "", "Error");
      });
    }
  };
  //custom_container_auth
  render() {
    const { t } = this.props;
    return (
      <div id='wrapper fixed-height'>
        <div className="no-bottom no-top" id="content">
          <div id="top"></div>
          
          <section id="subheader" className="text-light bglogin" data-bgimage="url(images/background/subheader.jpg) top">
            <div className="center-y relative text-center">
              <div className="container">
                <div className="row">

                  <div className="col-md-12 text-center">
                    <h1 className="logR">Forgot Password</h1>
                    {/* <p>Anim pariatur cliche reprehenderit</p> */}
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </section>



          <section aria-label="section">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2">
                  {/* <h3>Don't have an account? Register now.</h3> */}
                  {/* <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p> */}

                  <div className="spacer-10"></div>

                  {/* <form name="contactForm" id='contact_form' className="form-border" method="post" action='blank.php'> */}
                  <form className="form-signin">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={(e: any) => {
                        this.handleFiledUpdate("email", e.currentTarget.value);
                      }}
                      onKeyDown={this.handleKeyDown}
                      className="form-control" placeholder="Enter your e-mail" required autoFocus
                    />
                    <span className="error error_mssg">
                      {this.state.error.emailError}
                    </span>
                    <br />
                    <div>
                      <button className="btn btn-lg btn-primary btn-block" type="button" onClick={this.handleSubmit}>{t("submit")} </button>
                    </div>

                  </form>




                </div>
              </div>
            </div>
          </section>


        </div>


        <a href="#" id="back-to-top"></a>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {};
}

function mapDispatchToProps(dispatch: Function) {
  return {
    doForgotPassword: (payload: any, callback: Function, errorCallBack: Function) =>
      dispatch(doForgotPassword(payload, callback, errorCallBack)),
  };
}
const ForgotModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ForgotScreen)
);

export const Forgot = Applayout(ForgotModule);