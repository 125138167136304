import api from "./api";
import constant from "./constant";
import actionName from "./action.name";
import endpoints from "./endpoints";
//import firebase from "./firebase";
export default {
  api,
  constant,
  endpoints,
  actionName,
  //firebase,
};
