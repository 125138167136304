import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
import { GetNFTs } from "..";
import { NFTModal } from "../../../modal";
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
import web3Utills from '../../../utils/web3Utills';
import { UpdateNFT, UpdateNFTBuyNow } from "..";

const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
    GetNFTs?: any;
    UpdateNFT?: any;
    UpdateNFTBuyNow?: any;
    nftsList: any;
    updateNFTResult?: any;
}

interface State {
    lang: string;
    training: string;
}

export class BlockchainScreen extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            lang: "en",
            training: "Training"
        };

    }

    async componentDidMount() {
        //@ts-ignore
        window.renderCarousal();
        let language = window.localStorage.getItem('lang')
        //@ts-ignore
        this.setState({ lang: language }, () => {
            //@ts-ignore
            window.renderCarousal();
        })
        document.querySelectorAll('.lang_option')
            .forEach(item => {
                item.addEventListener('click', event => {
                    if (item) {
                        console.log(item.innerHTML.toLocaleLowerCase())
                        this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

                            //@ts-ignore
                            window.renderCarousal();
                        });

                    }
                });
            })

    }

    render() {

        return (
            <>
                <ApolloProvider client={apolloClient}>
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <h1>{this.state.lang == "en" ? en.op10 : fr.op10}</h1>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center video1">
                                    <iframe width="100%" height="450" src="https://www.youtube.com/embed/Ap4KU6rZiXs" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>

                                </div>

                            </div>
                        </div>
                        <div className="section3-2">
                            <div className="container">
                                <div className="row">
                                    <Query query={gql`
query Blockchain{
    blockchains(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                h1
                d1
                d2
                d3
            }
        }
     
    }
  

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            <div className="col-md-6">
                                                                <h2>{data.data.blockchains.data[1].attributes.h1}</h2>
                                                                <p>{data.data.blockchains.data[1].attributes.d1}</p>
                                                                {/* <a href="#" className="button-cta">{data.data.blockchains.data[1].attributes.button}</a> */}
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h2>{data.data.blockchains.data[2].attributes.h1}</h2>
                                                                <p>{data.data.blockchains.data[2].attributes.d1}</p>
                                                                {/* <a href="#" className="button-cta">{data.data.promotions.data[0].attributes.button}</a> */}
                                                            </div>

                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>


                                </div>
                            </div>
                        </div>
                        {/* <div className="section4-2">
                <div className="container"> 
                    <div className="row">
                    <Query query={gql`
query Testing{
    promotions(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                description
                button
                url
            }
        }
     
    }
  

  }`} >
                                    {
                                       
                                        //@ts-ignore
                                    (data:any)=>{
                                      console.log('data',data)
                                        return <div>
                                            {
                                            
                                                data.data?(<>
                                        
                        <div className="col-md-6">
                            <h3>{data.data.promotions.data[1].attributes.heading}</h3>
                            <p>{data.data.promotions.data[1].attributes.description}</p>
                            <a href="#" className="button-cta">{data.data.promotions.data[1].attributes.button}</a>
                        </div>
                        <div className="col-md-6 text-center">
                        <h2>{data.data.promotions.data[0].attributes.heading}</h2>
                            <p>{data.data.promotions.data[0].attributes.description}</p>
                            <a href="#" className="button-cta">{data.data.promotions.data[0].attributes.button}</a>
                            </div>

                                                </>):(<></>)
                                                  
                                                
                                           
                                            }
                                   
                                        </div>
                                    }
                                    }
                                      </Query>
                        {/* <div className="col-md-6">
                            <h3>Helping clients with research and strategy for their business</h3>
                            <p>Do you want to get a head start on the revolution brought by the new cryptographic currencies? Do you want to make a purchase of bitcoin or other crypto? Neoconomia is here to help you, train you and assist you in your first steps in crypto-economy, and show you the advantages offered by this new decentralized finance!!</p>
                            <a href="#" className="button-cta">See all services</a>
                        </div>
                        <div className="col-md-6 text-center"><img src="images/feature-training-image.png" className="img-responsive" /></div>
                    */}


                        {/* </div>    
                </div>
            </div>  */}
                        <div className="section3-2 see">
                            <div className="container">
                                <div className="row">
                                <Query query={gql`
query Blockchain{
    blockchains(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                h1
                d1
                d2
                d3
            }
        }
     
    }
  

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            <div className="col-lg-12">
                                        <h2>{data.data.blockchains.data[0].attributes.h1}</h2>
                                        <p>{data.data.blockchains.data[0].attributes.d1} <br/>
                                        {data.data.blockchains.data[0].attributes.d2} <br/>
                                        {data.data.blockchains.data[0].attributes.d3} </p>
                                        {/* <a href="#" className="button-cta">SEE ALL SERVICES</a> */}
                                    </div>
                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>
                                    {/* <div className="col-md-6">
                                        <h2>WHO SHOULD TAKE THIS COURSE?</h2>
                                        <p>The Blockchain technology: Business and Application is designed for professionals
                                            seeking a deeper knowledge of the impact and applications of blockchain
                                            technologies in an economic environment. <br />
                                            Whether you’re an entrepreneur looking to integrate blockchain into your business
                                            plan, or you’re in an industry such as retail or finance and wanting to understand the
                                            current and future developments of this new technology, this program will be relevant
                                            to you. <br />
                                            If you’re working directly within the strategic, operational, or managerial function, this
                                            program will equip you with the knowledge necessary to discover opportunities for
                                            efficiency and innovation using blockchain technology. </p>
                                        <a href="#" className="button-cta">SEE ALL SERVICES</a>
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <p id="back-top">
                            <a onClick={() => {window.scrollTo(0, 0)}}><i className="fa fa-angle-up"></i></a>
                        </p>
                    </React.Fragment>
                </ApolloProvider>
            </>
        );
    }
}

function mapStateToProps(state: any) {

    return {
        nftsList: state.homeReducer || {},
        updateNFTResult: state.UpdateNFTReducer || {},
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        GetNFTs: (payload: any, callback: Function) => dispatch(GetNFTs(payload, callback)),
        UpdateNFT: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFT(payload, token, callback)),
        UpdateNFTBuyNow: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFTBuyNow(payload, token, callback)),
    }
}

export const BlockchainModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(BlockchainScreen)
);

export const Blockchain = AppLayout(BlockchainModule);
