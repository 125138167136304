import React, { useState, memo, useEffect } from "react";
import { LogoutConfirmationModal } from "../../component/confirmation-modal/confirmationmodal";
import en from '../../languages/en/en.json'
import fr from '../../languages/fr/fr.json'
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { apolloClient } from "../../apollo/client";
import { Query } from 'react-apollo';
import { Container, Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import utils from "../../utils";
import { responsePathAsArray } from "graphql";


interface Props {
    changeLanguage?: any;
}
export default memo(function Onboardingheader(props: Props) {
    const [venci, setVenci] = useState("");
    // const [lang,setLang]=useState("en");
    const [logoutConfirmation, setLogoutConfirmation] = useState(false),
        //{ t, i18n } = useTranslation(),
        username = localStorage.getItem("username"),
        recruiterPic = localStorage.getItem("recruiterPic"),
        userid = localStorage.getItem("userId");
    localStorage.setItem("languageSelected", 'en');
    console.log(localStorage.getItem("languageSelected"))


    useEffect(() => {
        let language = window.localStorage.getItem('lang')
        if (language == null) {
            console.log("in the if")
            window.localStorage.setItem('lang', 'en')
            language = "en"
            setLang(language)
        }
        else {
            setLang(language)
        }

    }, []);

    let jwt = window.localStorage.getItem('jwt');
    console.log("+++++++++++", jwt);
    let user = window.localStorage.getItem('username');
    console.log("username:", user);

    let sessionData = {
        // username : response.data.user.username;
    }


    function handleLogout() {
        localStorage.clear();
        localStorage.setItem("lang", 'en');
        window.location.href = '/home';
    }


    function checkFunctionality() {
        var protocol = 'https';
        var userid = localStorage.getItem("userId");


        var page = window.location;
        if (page.href.includes('https')) { protocol = 'https' }
        else if (page.href.includes('http')) { protocol = 'http'; }
        console.log(page)
        console.log("testnet update ?")
        // if (page.href.includes('my-account-wallet')) { window.location.href = "/buy-select-ammount" }
        // else if (page.href.includes('home') || page.href == protocol + "://" + page.host + "/") { window.location.href = userid == "" ? '/signin' : "/buy-select-ammount" }
        // else if (page.href.includes('learnmore')) { window.location.href = userid == "" ? '/signin' : "/buy-select-ammount" }
        //window.location.href='/signin' userid = localStorage.getItem("userId");
    }
    const [lang, setLang] = useState('en')
    useEffect(() => {
        document.querySelectorAll('.lang_option')
            .forEach(item => {
                item.addEventListener('click', event => {
                    if (item) {
                        console.log('footer', item.innerHTML.toLocaleLowerCase())
                        setLang(item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr");
                    }
                });
            })
    }, [])
    return (
        <>
            <nav className="navbar navbar-default navbar-fixed-top">
                <div className="container">

                    <div className="navbar-header page-scroll manual">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand page-scroll " href="/#/home"><img src="images/logo5.png" style={{ width: "85%" }} alt="Sanza theme logo" /></a>
                    </div>


                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav navbar-right">
                            <li className="hidden">
                                <a href="#page-top"></a>
                            </li>
                            <li>
                                <a className="page-scroll" href="/#/home">{lang == "en" ? en.op1 : fr.op1}</a>
                            </li>
                            <li>
                                <a className="page-scroll" href="/#/whoweare">{lang == "en" ? en.op2 : fr.op2}</a>
                            </li>
                            {/* <li>
							<a className="page-scroll" href="#">{lang=="en"?en.op3:fr.op3}   </a>
						</li> */}

                            <li >
                                <button className="btn2" type="button" id="dropdownMenuButton" data-toggle="dropdown">{lang == "en" ? en.op4 : fr.op4}
                                    <span className="caret"></span></button>
                                <ul className="dropdown-menu">
                                     <li><a className="a2" href="/#/blockchaineducation">{lang == "en" ? en.op10 : fr.op10}</a></li>
                                     <li><a className="a2" href="/#/blockchainconsultation">{lang == "en" ? en.op11 : fr.op11}</a></li>
                                    <li><a className="a2" href="/#/crypto">{lang == "en" ? en.op9 : fr.op9}</a></li>
                                
                                </ul>
                            </li>

                            <li>
                                <a className="page-scroll" href="/#/trainings">{lang == "en" ? en.op5 : fr.op5}</a>
                            </li>
                            <li>
                                <a className="page-scroll" href="/#/services">{lang == "en" ? en.op14 : fr.op14}</a>
                            </li>

                            {/* <li>
							<a className="page-scroll" href="#">{lang=="en"?en.op12:fr.op12}</a>
						</li>
                        <li>
							<a className="page-scroll" href="#">{lang=="en"?en.op13:fr.op13}</a>
						</li> */}
                            <li>
                                <a className="page-scroll" href="/#/contactus">{lang == "en" ? en.op40 : fr.op40}</a>
                            </li>

                            {
                                jwt ? (<>
                                    <li>
                                        <button className="btn2 btt2" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                            {localStorage.getItem("username")}
                                            {/* <i className="fa fa-user" aria-hidden="true"></i> */}
                                            <span className="caret"></span></button>
                                        <ul className="dropdown-menu">
                                            {/* <li><span className="user">{localStorage.getItem("username")}</span></li> */}
                                            {/* <li><a className="lang_option a2" href="/register">Sign Up</a></li> */}
                                            <li><a className="" onClick={() => { handleLogout() }}>Logout</a></li>
                                        </ul>
                                    </li>
                                </>) : (<>
                                    <li>
                                        {/* <button className="btn2 but22" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                                <a className="page-scroll" href="">Login/Register</a>
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    
                                    <span className="caret"></span>
                                    </button>
                                <ul className="dropdown-menu">
                                    <li><a className="" href="/register">Register</a></li>
                                    <li><a className="" href="/login">Login</a></li>
                                </ul> */}
                                        <a className="page-scroll" href="/#/register">{lang == "en"? en.op35 : fr.op35}</a>
                                    </li>
                                </>)
                            }

                            {/* <li>
                            <div>
                                <a className="page-scroll">EN</a> | <a className="page-scroll">FR</a>
                            </div>
                        </li> */}
                            <li className="langu" style={{ paddingTop: "3px" }}>
                                <ul className="language_options">
                                    <a className="lang_option a2" onClick={() => {
                                        window.localStorage.setItem('lang', 'en')
                                    }}>EN</a> <span style={{ color: "white" }}>|</span> <a className="lang_option a2" onClick={() => {
                                        window.localStorage.setItem('lang', 'fr')
                                    }}>FR</a>
                                </ul>
                            </li>

                            {/* <li>
                            <button className="btn3" type="button" id="dropdownMenuButton" data-toggle="dropdown">{lang=="en"?"EN":"FR"}
                                <span className="caret"></span></button>
                                <ul className="dropdown-menu language_options">
                                  <li><a className="lang_option a2" onClick={()=>{
                                      window.localStorage.setItem('lang','en')
                                  }}>English</a></li>
                                  <li><a  className="lang_option a2" onClick={()=>{
                                      window.localStorage.setItem('lang','fr')
                                  }}>French</a></li>
                                </ul>                           
                          </li> */}

                            <li>
                                <div className="btn-buy"><a className="btn" href="/#/payment">{lang == "en" ? en.op8 : fr.op8}</a></div>
                            </li>
                        </ul>

                    </div>


                </div>
                {/* <ApolloProvider client={apolloClient}>
              <React.Fragment>
              <Query query={gql`
query Nav{
    navs(locale: "${lang}"){
        data{
            id
            attributes{
               
                op1
                op2
                op3
                op4
                op5
                op6
                op7
                op8
               op9
               op10
               op11
            }
        }
     
    }
   

  }`} >
                                    {
                                       
                                        //@ts-ignore
                                    (data:any)=>{
                                      console.log('data',data)
                                        return <div>
                                            {
                                            
                                                data.data?(<>
                                        <div className="container">
				
				<div className="navbar-header page-scroll">
					<button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
					<span className="sr-only">Toggle navigation</span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					</button>
					<a className="navbar-brand page-scroll" href="#page-top"><img src="images/logo.png" alt="Sanza theme logo" /></a>
				</div>
                <div className="btn-buy"><a href="#" className="btn">{data.data.navs.data[0].attributes.op8}</a></div>
				
				<div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
					<ul className="nav navbar-nav navbar-right">
						<li className="hidden">
							<a href="#page-top"></a>
						</li>
						<li>
							<a className="page-scroll" href="#">{data.data.navs.data[0].attributes.op1}</a>
						</li>
						<li>
							<a className="page-scroll" href="#">{data.data.navs.data[0].attributes.op2}</a>
						</li>
						<li>
							<a className="page-scroll" href="#">{data.data.navs.data[0].attributes.op3}    </a>
						</li>
                        <li>
                            <a className="" type="button" data-toggle="dropdown">{data.data.navs.data[0].attributes.op4}
                                <span className="caret"></span></a>
                                <ul className="dropdown-menu">
                                  <li><a href="#">{data.data.navs.data[0].attributes.op9}</a></li>
                                  <li><a href="#">{data.data.navs.data[0].attributes.op10}</a></li>
                                  <li><a href="#">{data.data.navs.data[0].attributes.op11}</a></li>
                                </ul>                           
                          </li>
						<li>
							<a className="page-scroll" href="#">{data.data.navs.data[0].attributes.op5}</a>
						</li>
					 
						<li>
							<a className="page-scroll" href="#contact">{data.data.navs.data[0].attributes.op6}</a>
						</li>
                        <li>
                            <a className="" type="button" data-toggle="dropdown">{data.data.navs.data[0].attributes.op7}
                                <span className="caret"></span></a>
                                <ul className="dropdown-menu language_options">
                                  <li><a href="#" className="lang_option">English</a></li>
                                  <li><a href="#"  className="lang_option">French</a></li>
                                </ul>                           
                          </li>
						
					</ul>
                    
				</div>
			
               
			</div>
                                                </>):(<></>)
                                                  
                                                
                                           
                                            }
                                   
                                        </div>
                                    }
                                    }
                                      </Query>
			
            </React.Fragment>  
            </ApolloProvider> */}
            </nav>




            <LogoutConfirmationModal
                confirm={handleLogout}
                modalShow={logoutConfirmation}
                onHide={() => {
                    setLogoutConfirmation(false);
                }}
            />

        </>
    );
});
