import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
import { GetNFTs } from "..";
import { NFTModal } from "../../../modal";
import web3Utills from '../../../utils/web3Utills';
import { UpdateNFT, UpdateNFTBuyNow } from "..";

const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
    GetNFTs?: any;
    UpdateNFT?: any;
    UpdateNFTBuyNow?: any;
    nftsList: any;
    updateNFTResult?: any;
}

interface State {
    lang: string;
    training: string;
}

export class TrainingsScreen extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            lang: "en",
            training: "Training"
        };

    }

    async componentDidMount() {

        //@ts-ignore
        window.renderCarousal();
        let language = window.localStorage.getItem('lang')
        //@ts-ignore
        this.setState({ lang: language })
        document.querySelectorAll('.lang_option')
            .forEach(item => {
                item.addEventListener('click', event => {
                    if (item) {
                        console.log(item.innerHTML.toLocaleLowerCase())
                        this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" });
                    }
                });
            })

    }

    render() {

        return (
            <>
                <ApolloProvider client={apolloClient}>
                    <React.Fragment>
                        {/* data.data.theories.data[0].attributes */}
                        {/* <Query query={gql`
query Training{
    trainings(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                description
                url

            }
        }
     
    }
    

  }`} >
                                    {
                                       
                                        //@ts-ignore
                                    (data:any)=>{
                                      console.log('data',data)
                                        return <div>
                                            {
                                            
                                                data.data?(<>
                                                       <section className="page">

<div className="container">
    <div className="row">
        <div className="col-md-8">
            <h2> {data.data.trainings.data[0].attributes.heading}</h2>
            <p>{data.data.trainings.data[0].attributes.description}</p>
        



        </div>

        <div className="col-md-4"><img src={data.data.trainings.data[0].attributes.url} className="img-responsive" alt="" /></div>
    </div>


   

</div>    

</section> 					 
                      </>):(<></>)
                                                  
                                                
                                           
                                            }
                                   
                                        </div>
                                    }
                                    }
                                      </Query>	
           */}

                        <section className="page">
 

                                    <Query query={gql`
query Training{
    trainings(locale: "${this.state.lang}"){
        data{
            id
            attributes{
               h1
               h2
               h3
               h4
               h5
               h6
               h7
               h8
               d1
               d2
               d3
               d4
               d5
               l1
               l2
               l3
               l4
               l5
               l6
               d8

            }
        }
     
    }
    

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <>
                                                    {

                                                        data.data ? (<>
                                                        <div className="container">
                                                        <div className="row" style={{paddingBottom:"25px"}}>
                                                                <div className="col-md-8">
                                                                    <h4>{data.data.trainings.data[0].attributes.h1}</h4>
                                                                </div>
                                                                <div className="col-md-4 course duration-button" style={{width:"222px"}}>
                                                                    <h4 style={{fontSize: "18px", fontWeight: "normal", paddingTop: "0px"}}>Durée 6 Semaines</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="container">
                                                        <div className="row">
                                                             <div className="col-md-8">
                                                        
                                                                <p>{data.data.trainings.data[0].attributes.d1}</p>
                                                                <h4>{data.data.trainings.data[0].attributes.h2}</h4>
                                                                <p>{data.data.trainings.data[0].attributes.d2}</p>
                                                                <h4>{data.data.trainings.data[0].attributes.h3}</h4>
                                                                <p>{data.data.trainings.data[0].attributes.d3}</p>
                                                                <h4>{data.data.trainings.data[0].attributes.h4}</h4>
                                                                <p>{data.data.trainings.data[0].attributes.d4}</p>
                                                                <h4>{data.data.trainings.data[0].attributes.h5}</h4>
                                                                <p>{data.data.trainings.data[0].attributes.d5}</p>
                                                                <h4>{data.data.trainings.data[0].attributes.h6}</h4>
                                                                <ul style={{ listStyle: "none" }}>
                                                                    <li>{data.data.trainings.data[0].attributes.l1}</li>
                                                                    <li>{data.data.trainings.data[0].attributes.l2}</li>
                                                                    <li>{data.data.trainings.data[0].attributes.l3}</li>
                                                                    <li>{data.data.trainings.data[0].attributes.l4}</li>
                                                                </ul>
                                                                <h4>{data.data.trainings.data[0].attributes.h7}</h4>
                                                                <ul style={{ listStyle: "none" }}>
                                                                    <li>{data.data.trainings.data[0].attributes.l5}
                                                                        <ul style={{ listStyle: "none" }}>
                                                                            <li>{data.data.trainings.data[0].attributes.l6}</li></ul></li>
                                                                </ul>
                                                                <h4>{data.data.trainings.data[0].attributes.h8}</h4>
                                                                <p>{data.data.trainings.data[0].attributes.d8}</p>
                                                            </div>
                                                            
                                                            <div className="col-md-4"><img src="images/books.jpg" className="img-responsive" alt="" /></div>
                                                            </div>
                                                            </div>
                                                        </>) : (<></>)



                                                    }

                                                </>
                                            }
                                        }
                                    </Query>


                                    {/* <div className="col-md-8">
                                        <h2>Technologie Blockchain : Innovation Commerciale et applications</h2>
                                        <p>La technologie blockchain a le potentiel de changer fondamentalement la façon dont les affaires
                                            sont menées et de transformer les fondements de nos systèmes économiques et sociaux. Le cours
                                            Technologie Blockchain : Innovation Commerciale et Application s'appuie sur les travaux du
                                            principal professeur du MIT (Massachusetts Institute of technology) et expert en cryptoéconomie Christian Catalini, et examine les technologies de la blockchain d'un point de vue
                                            économique.
                                            En mettant l'accent sur ses opportunités et ses limites organisationnelles, le programme vous
                                            fournira les outils nécessaires pour tirer parti de la technologie blockchain pour stimuler
                                            l'innovation et l'efficacité dans votre entreprise. À la fin, vous serez en mesure de mettre en œuvre
                                            une solution basée sur la blockchain pour résoudre un problème commercial dans votre propre
                                            contexte.</p>
                                        <h2>Ce cours est-il pour vous ?</h2>
                                        <p>Ce cours abrégé sur la blockchain n'est pas technique, mais se concentre plutôt sur les applications
                                            de la technologie blockchain pour les entreprises. Il s'adresse aux dirigeants, aux managers,
                                            Cadres et employés qui souhaitent approfondir leur compréhension de l'impact des technologies
                                            blockchain dans un environnement économique et identifier les domaines de mise en œuvre dans
                                            leur organisation.</p>
                                        <h2>Qu'est-ce que la mise en œuvre de la blockchain ?</h2>
                                        <p>En ce qui concerne la technologie blockchain, il existe quelques méthodes de mise en œuvre parmi
                                            lesquelles les entreprises peuvent choisir. La méthode de mise en œuvre la plus courante est une
                                            blockchain privée. Dans cette implémentation, la blockchain est contrôlée par une seule
                                            organisation ou un groupe d'organisations. Cela permet aux entreprises de mieux contrôler qui a
                                            accès à la blockchain et comment elle est utilisée.</p>
                                        <h2>Développement de la blockchain</h2>
                                        <p>Avant que les entreprises puissent commencer à mettre en œuvre les technologies de la blockchain,
                                            elles doivent comprendre les bases des concepts et du développement de la blockchain. Découvrez
                                            les bases de données distribuées qui permettent des transactions sécurisées et transparentes. Ce
                                            cours de courte durée peut vous permettre de comprendre les solutions, les réseaux, les
                                            écosystèmes, les concepts et le développement de la blockchain.</p>
                                        <h2>Comprendre les applications métier pouvant être implémentées</h2>
                                        <p>Ce cours sur la blockchain peut vous apprendre à exploiter la puissance de cette nouvelle
                                            technologie passionnante. En plus d'apprendre l'impact des technologies blockchain dans un
                                            environnement économique, vous acquerrez également une compréhension plus approfondie de
                                            ses applications commerciales. Cela peut vous aider à déterminer si la blockchain convient à votre
                                            entreprise.</p>
                                        <h2>Qu'est-ce qui vous distinguera à la	fin	de ce cours, vous gagnerez :</h2>
                                        <ul>
                                            <li>Une	 compréhension	 plus	 approfondie	 des	 capacités	 de	 la	 technologie	 blockchain,
                                                vous permettant	d'évaluer	les	problèmes	commerciaux	qu'elle	peut	résoudre</li>
                                            <li>Une	stratégie	basée	sur	la	blockchain	qui	répond	à	un	défi	commercial	au	sein	de	votre
                                                secteur.</li>
                                            <li>Une	compréhension	approfondie	de	la	façon	dont	la	technologie	blockchain	réduit	le
                                                coût	de	la	vérification	et	le	coût	de	la	mise	en	réseau.</li>
                                            <li>Connaissance	 de	 la	 façon	 dont	 les	 applications	 blockchain	 telles	 que	 Bitcoin	 et
                                                d'autres	initiatives	basées	sur	des	jetons	sont	alimentées.</li>
                                        </ul>
                                        <h2>Aperçu	du	cours</h2>
                                        <ul>
                                            <li>73% - La	proportion	de	cadres	financiers	qui	pensent	que	leur	organisation
                                                perdra	une	opportunité	d'avantage	concurrentiel	si	elle	n'adopte	pas	la
                                                blockchain. Deloitte	(2021).
                                                <ul>
                                                    <li>40	Millions Le	nombre	d'emplois	que	la	blockchain	devrait	affecter
                                                        ou	améliorer	d'ici	2030. PwC	(2020)</li></ul></li>
                                        </ul>
                                        <h2>A la fin de votre formation </h2>
                                        <p>Développez	vos	connaissances	sur	la	blockchain	et	obtenez	un	certificat	numérique	officiel d'accomplissement de	note	école qui	sera	reconnu	partout	au	pays	et	à	l’international.</p>
                                    </div> */}

                                    {/* <div className="col-md-4"><img src="images/books.jpg" className="img-responsive" alt="" /></div> */}
                                 

                        </section>

                        <p id="back-top">
                            <a onClick={() => {window.scrollTo(0, 0)}}><i className="fa fa-angle-up"></i></a>
                        </p>
                    </React.Fragment>
                </ApolloProvider>
            </>
        );
    }
}

function mapStateToProps(state: any) {

    return {
        nftsList: state.homeReducer || {},
        updateNFTResult: state.UpdateNFTReducer || {},
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        GetNFTs: (payload: any, callback: Function) => dispatch(GetNFTs(payload, callback)),
        UpdateNFT: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFT(payload, token, callback)),
        UpdateNFTBuyNow: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFTBuyNow(payload, token, callback)),
    }
}

export const TrainingsModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(TrainingsScreen)
);

export const Trainings = AppLayout(TrainingsModule);
