import axios from "axios";
import Utils from "../utils";
import { UserDataModal, JobListsModal } from "../modal";
import {
  removeSession,
  getUserIdToken,
  setSession,
  getOtpVerification,
  getProfileVerification,
} from "./session";
import i18n from "../i18n";

/**
 * setup axios instance
 */
const $axios = axios.create({
  baseURL: `${process.env.REACT_APP_DOMAIN_URL}${process.env.REACT_APP_VERSION_NO}/divenci/`,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
    Authorization: process.env.REACT_APP_AUTHORIZATION,
    lng: i18n && i18n.language && i18n.language == "nl" ? i18n.language : "en",
    userId: window.localStorage.getItem("userId")
  },
  auth: {
    username: process.env.REACT_APP_API_USER_NAME || "",
    password: process.env.REACT_APP_API_USER_PASS || "",
  },
});
const API_ERROR_CODE = {
  emailExits: 409,
  timeout: 408,
  invalidEmail: 400,
  inValidUser: 501,
};
function hasConnection() {
  if (!navigator.onLine) {
    let element = document.getElementById("network-error-card");
    if (element) {
      element.classList.add("active");
      setTimeout(function () {
        if (element) {
          element.classList.remove("active");
        }
      }, 3000);
    }
  }
  return navigator.onLine;
}

function handleOverlayLoader(showLoader: boolean = false) { }

function apiErrorMessage(message: string) {
  let element = document.getElementById("body-message");
  if (element) {
    element.classList.add("active");
    let errorElement = document.getElementById("api-error-message");
    if (errorElement) {
      element.style.backgroundColor = "#303030";
      element.style.borderColor = "#303030";

      errorElement.innerHTML = message;
    }
    setTimeout(function () {
      if (element) {
        element.classList.remove("active");
      }
      if (errorElement) {
        errorElement.innerHTML = "";
      }
    }, 3000);
  }
}

function successMessage(message: string) {
  let element = document.getElementById("body-message");
  if (element) {
    element.classList.add("active");
    let errorElement = document.getElementById("api-error-message");
    if (errorElement) {
      element.style.backgroundColor = "#00baee";
      element.style.borderColor = "#00baee";
      errorElement.innerHTML = message;
    }
    setTimeout(function () {
      if (element) {
        element.classList.remove("active");
      }
      if (errorElement) {
        errorElement.innerHTML = "";
      }
    }, 3000);
  }
}
/**
 * global api error handler
 */
function handleApiError(payload: any, dispatch: Function, errorType?: string) {
  if (payload.status === API_ERROR_CODE.emailExits) {
    apiErrorMessage("Email id already exists");
    return false;
  } else if (payload.status === API_ERROR_CODE.invalidEmail) {
    apiErrorMessage("Invalid Credential");
    return false;
  } else if (payload.data.status === API_ERROR_CODE.timeout) {
    apiErrorMessage("Request Timeout");
    return false;
  } else if (payload.status === API_ERROR_CODE.inValidUser) {
    apiErrorMessage("THIS USER DOES NOT EXIST OR LOGGED INTO ANOTHER DEVICE.");
    Utils.constant.isLogin = false;
    Utils.constant.userId = "";
    Utils.constant.userName = "";
    removeSession();
    dispatch({
      type: Utils.actionName.USER_LOGOUT,
      payload: new UserDataModal(),
    });
    window.location.reload();
  } else {
    apiErrorMessage("Some thing went wrong");
  }
}

const emailErr = window.localStorage.getItem("error");
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const emailExits = "Email already exists";
const nameRegex = /^[^ +]([^0-9~!@#$%^&*()|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]{1,})$/;
const lastNameRegex = /^[^ +]([^0-9~!@#$%^&*()|+\-=÷¿?;:'",.<>\{\}\[\]\\\/]{0,})$/;
const phonenumberRegx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const salaryRegex = /^\d+$/;

function validateEmail(email: string) {
  let error = "";
  const t = i18n.t.bind(i18n);
  if (!email) {
    error = t("emailEmptyError");
  } else if (!emailRegex.test(email)) {
    error = t("wrongemailerror");
  }
  else{
    error = "";
  }
  return error;
}
function validatePassword(
  password: string,
  msg: string = "Please enter your password"
) {
  const t = i18n.t.bind(i18n);
  let error = "";
  if (!password) {
    error = t("enterPassword");
  } else if (password.length < 6) {
    error = t("passwordlengthError");
  }
  else {
    error = "";
  }
  return error;
}
function validateConfirmPassword(
  password: string,
  confirmPassword: string,
  msg: string = "Please enter your password"
) {
  const t = i18n.t.bind(i18n);
  let error = "";
  if (!password) {
    error = t("enterPassword");
  } else if (password.length < 6) {
    error = t("passwordlengthError");
  } else if (confirmPassword !== password) {
    error = t("confirmpasswordError");
  }
  else {
    error = "";
  }
  return error;
}



function validateChangePassword(newPassword: string, confirmPassword: string) {
  let error = "";
  const t = i18n.t.bind(i18n);

  if (!confirmPassword) {
    error = t("enterconfirmpassword");
  } else if (confirmPassword !== newPassword) {
    error = t("confirmpasswordError");
  } else {
    error = "";
  }
  return error;
}

function validateFirstName(firstname: string) {
  let error = "";
  const t = i18n.t.bind(i18n);

  if (!firstname) {
    error = t("pleaseenterfirstname");
  } else if (firstname.length < 2) {
    error = t("firstnamecharactererr");
  } else if (!nameRegex.test(firstname)) {
    error = t("invalidfirstname");
  } else {
    error = "";
  }
  return error;
}

function validateLastName(lastname: string) {
  let error = "";
  const t = i18n.t.bind(i18n);

  if (!lastname) {
    error = t("pleaseenterlastname");
  } else if (lastname.length < 2) {
    error = t("lastnamecharactererr");
  } else if (!lastNameRegex.test(lastname)) {
    error = t("invalidlastname");
  } else {
    error = "";
  }
  return error;
}

function validateField(key: string, field: string) {
  let error = "";

  const t = i18n.t.bind(i18n);

  if (!field) {
    error = key + " is required!";

  }
  // else if (field.length < 2) {
  //   error =  key+" should be atleast 2 character.";
  // } 
  else {
    error = "";
  }

  return error;
}

function validateMessage(lastname: string) {
  let error = "";
  const t = i18n.t.bind(i18n);

  if (!lastname) {
    error = "Please Enter Message";
  }
  else {
    error = "";
  }
  return error;
}

function validatePhoneNumber(phonenumber: string) {
  phonenumber = phonenumber.replace(/ |-/g, "");
  const t = i18n.t.bind(i18n);

  let error = "";
  if (!phonenumber) {
    error = t("entermobilenumber");
  } else if (phonenumber.length < 10) {
    error = t("mobilenumbermoredigits");
  } else if (!phonenumberRegx.test(phonenumber)) {
    error = t("invalidmobileerror");
  } else {
    error = "";
  }
  return error;
}
function validateCheckSpecialCharacter(socialmedianame: string) {
  
  let error = "";
 if(socialmedianame!=''&&socialmedianame.includes('@'))
 {
   error='Do not use a @ in front of your username'
 }
  return error;
}

function validateCompanyName(companyname: string) {
  let error = "";
  const t = i18n.t.bind(i18n);
  if (!companyname) {
    error = t("pleaseentercompanyname");
  } else if (companyname.length < 2) {
    error = t("companynameshorterr");
  } else {
    error = "";
  }
  return error;
}

function validateForm(obj: any) {
  let formError = obj.error;

  for (let key in obj) {
    if (key !== "error") {
      switch (key) {
        case "email":
          formError.emailError = validateEmail(obj[key]);
          break;
        case "password":
          formError.passwordError = validatePassword(obj[key]);
          break;
        case "passwordConfirm":
          formError.passwordConfirmError = validateConfirmPassword(obj["password"], obj[key]);
          break;
        case "textArea":
          formError.passwordConfirmError = validateMessage(obj[key]);
          break;
        case "telephone":
          if (!obj[key]) { formError.telephoneError = "Please Enter Phone" } else { formError.telephoneError = "" }
          break;
        case "country":
          if (!obj[key]) { formError.countryError = "Please enter country" } else { formError.countryError = "" }
          break;
        case "name":
          if (!obj[key]) { formError.nameError = "*" } else { formError.nameError = "" }
          break;
        case "username":
          var format = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/;
          if (!obj[key]) { formError.usernameError = "Please Enter User Name" }
          else if (format.test(obj[key])) { formError.usernameError = "Username should not contain special characters" }
          else { formError.usernameError = "" }
          break;
        case "newpassword":
          formError.newpasswordError = validatePassword(
            obj[key],
            "Please enter new password"
          );
          break;
          case "address":
          if (!obj[key]) { formError.addressError = "Please Enter Address" } else { formError.addressError = "" }
          break;
          case "date":
          if (!obj[key]) { formError.dateError = "Please Select Date of Birth" } else { formError.dateError = "" }
          break;
          case "diploma":
          if (!obj[key]) { formError.diplomaError = "Please Enter Last Diploma Obtained" } else { formError.diplomaError = "" }
          break;
          case "profession":
          if (!obj[key]) { formError.professionError = "Please Select Relevant Field" } else { formError.professionError = "" }
          break;
          case "gender":
          if (!obj[key]) { formError.genderError = "Please Select Relevant Field" } else { formError.genderError = "" }
          break;
        case "confirmpassword":
          formError.confirmpasswordError = validateChangePassword(
            obj["newpassword"],
            obj[key]
          );
          break;

        case "firstname":
          formError.firstnameError = validateFirstName(obj[key]);
          break;
        case "companyname":
          formError.companynameError = validateCompanyName(obj[key]);
          break;

        case "lastname":
          formError.lastnameError = validateLastName(obj[key]);
          break;

        case "phonenumber":
          formError.phonenumberError = validatePhoneNumber(obj[key]);
          break;
          case "twitterUsername":
          formError.twitterUsernameError = validateCheckSpecialCharacter(obj[key]);
          break;
          case "instaUsername":
          formError.instaUsernameError = validateCheckSpecialCharacter(obj[key]);
          break;
         
          
      }
    }
  }

  return formError;
}


function validatePasswordForm(obj: any) {

  let formError = obj.error;

  console.log(formError)
  for (let key in obj) {
    if (key !== "error") {
      switch (key) {
        case "password":
          formError.passwordError = validatePassword(obj[key]);
          break;
        case "passwordConfirm":
          formError.passwordConfirmError = validateConfirmPassword(obj["newPassword"], obj[key]);
          break;
        case "newPassword":
          formError.newpasswordError = validatePassword(
            obj[key],
            "Please enter new password"
          );
          break;
      }
    }
  }

  return formError;
}

function validateFormGeneric(obj: any) {

  let formError = obj.error;

  for (let key in obj) {
    if (key !== "error") {
      let keyName = key + "Error";
      formError[keyName] = validateField(key, obj[key]);
    }
  }

  return formError;
}

function getnum(key: any = 0) {
  if (key && isNaN(Number(key[key.length - 1]))) {
    key = key[key.length - 1].charCodeAt(0) - 97;
  } else if (key) {
    key = Number(key[key.length - 1]);
  }
  key = key >= 10 ? key % 10 : key;
  return key;
}

function getInitials(string: string) {
  if (typeof string == "string" && string) {
    let names = string.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  } else {
    return "U";
  }
}

/**
 *
 * @param timestamp time to format
 */
const formatTimeAMPM = (timestamp: any) => {
  if (timestamp) {
    let date = new Date(timestamp);
    let hours = date.getHours();
    let minutes: any = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  }
  return "";
};

/**
 * @param fn - function
 * @param delay - timeout
 */
const debounce = function (fn: Function, d: number) {
  let timer: any;
  return function (...args: any) {
    //@ts-ignore
    let context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, d);
  };
};

enum userType {
  jobSeeker = 1,
  recruiter = 2,
}

const amazonS3Config = {
  bucketName: "grabjob",
  region: "eu-central-1",
  accessKeyId: "AKIAR6UJFNLUVHWBF274",
  secretAccessKey: "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",
};

export default {
  userType,
  setSession,
  removeSession,
  hasConnection,
  successMessage,
  handleApiError,
  getUserIdToken,
  apiErrorMessage,
  handleOverlayLoader,
  formatTimeAMPM,
  debounce,
  getnum,
  getInitials,
  amazonS3Config,
  userId: "",
  userName: "",
  axios: $axios,
  isLogin: false,
  validateForm: validateForm,
  validateFormGeneric,
  validatePasswordForm: validatePasswordForm,
  getOtpVerification,
  getProfileVerification,
  apiUrl: `${process.env.REACT_APP_DOMAIN_URL}${process.env.REACT_APP_VERSION_NO}/divenci/`,
  apiUrl2: `${process.env.REACT_APP_DOMAIN_URL}${process.env.REACT_APP_VERSION_NO}/`,
  vinciContractAddress: `${process.env.VENCI_COIN_CONTRACT_ADD}`
};