import Utils from "../utils";
import axios from "axios";
import { error } from "console";
/**
 *
 * @param endPoint api end point
 * @param params request data
 * @param successCallback function for handle success response
 * @param errorCallback  function for handle error response
 */
const postApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["Authorization"] =
    "Basic YWRtaW5AZ3JhYmpvYnMuY29tOlBhc3NAd29yZDE=";
  Utils.constant.axios.defaults.headers.common[
    "authToken"
  ] = localStorage.getItem("accessToken")
      ? localStorage.getItem("accessToken")
      : localStorage.getItem("accessToken")
        ? localStorage.getItem("accessToken")
        : localStorage.getItem("accessToken");
  Utils.constant.axios
    .post(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};

/** postApiCallForOtp */
const postApiCallForOtp = (
  endPoint: string,
  params: object,
  token: string,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["Authorization"] =
    "Basic YWRtaW5AZ3JhYmpvYnMuY29tOlBhc3NAd29yZDE=";
  Utils.constant.axios.defaults.headers.common["authToken"] = token;
  Utils.constant.axios
    .post(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};
/** postApiCallChangeLanguage */
const postApiCallChangeLanguage = (
  endPoint: string,
  params: object,
  token: string,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["Authorization"] =
    "Basic YWRtaW5AZ3JhYmpvYnMuY29tOlBhc3NAd29yZDE=";
  Utils.constant.axios.defaults.headers.common["authToken"] = token;
  Utils.constant.axios
    .put(endPoint, params)
    .then((response: any) => {
      console.log(endPoint + '' + JSON.stringify(params))
      console.log(response)
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        console.log('ding ding error')
        errorCallback(error.response);
      } else if (!error.response) {
        console.log('ding ding error 2')
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};
/** postApiCallWithoutWebInUrl */
const postApiCallWithoutWebInUrl = (
  endPoint: string,
  params: object,
  token: string,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["Authorization"] =
    "Basic YWRtaW5AZ3JhYmpvYnMuY29tOlBhc3NAd29yZDE=";
  Utils.constant.axios.defaults.headers.common["authToken"] = token;
  Utils.constant.axios
    .post(Utils.constant.apiUrl2 + endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};
/** postApiCallWithoutWebInUrl */
const postMultipartFormData = (
  endPoint: string,
  formData: FormData,
  token: string,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["Authorization"] = "Basic YWRtaW5AZ3JhYmpvYnMuY29tOlBhc3NAd29yZDE=";
  Utils.constant.axios.defaults.headers.common["authToken"] = token;
  //Utils.constant.axios.defaults.headers.common["Content-type"] = "multipart/form-data";
  Utils.constant.axios
    .post(Utils.constant.apiUrl2 + endPoint, formData)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      console.log(error);
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};


/** putApiCallWithoutWebInUrl */
const putApiCallWithoutWebInUrl = (
  endPoint: string,
  params: object,
  token: string,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["Authorization"] =
    "Basic YWRtaW5AZ3JhYmpvYnMuY29tOlBhc3NAd29yZDE=";
  Utils.constant.axios.defaults.headers.common["authToken"] = token;
  Utils.constant.axios
    .put(Utils.constant.apiUrl2 + endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};
/**
 *
 * @param endPoint api end point
 * @param params api url parameter
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const getApiCall = (
  endPoint: string,
  params: string = "",
  token: string = "",
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["authToken"] = token;
  Utils.constant.axios
    .get(Utils.constant.apiUrl + endPoint + params, {})
    .then((response: any) => {

      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};

// /####################################################################/

const getApiCallWithoutWebInUrl = (
  endPoint: string,
  params: string = "",
  token: string = "",
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["authToken"] = token;
  Utils.constant.axios
    .get(Utils.constant.apiUrl2 + endPoint + params, {})
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};

/**********************************************************************************************/
/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const deleteApiCall = (
  endPoint: string,
  params: string = "",
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios
    .delete(Utils.constant.apiUrl + endPoint + params, {})
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const patchApiCall = (
  endPoint: string,
  params: object,
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios
    .patch(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const putApiCall = (
  endPoint: string,
  params: object,
  token: string = "",
  successCallback: Function,
  errorCallback: Function
) => {
  Utils.constant.axios.defaults.headers.common["authToken"] = token;
  Utils.constant.axios
    .put(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error: any) => {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};

/**
 *
 * @param endPoint api end point
 * @param params api request data
 * @param successCallback function for handle success response
 * @param errorCallback function for handle error response
 */
const contentApiCall = (
  endPoint: string,
  params: "",
  successCallback: Function,
  errorCallback: Function
) => {
  axios
    .get(endPoint)
    .then(function (response) {
      successCallback(response);
    })
    .catch(function (error) {
      if (error.code === "ECONNABORTED") {
        let payload = {
          data: {
            status: 408,
          },
        };
        errorCallback(payload);
      } else if (error.response) {
        errorCallback(error.response);
      } else if (!error.response) {
        let payload = {
          data: {
            status: "",
          },
        };
        errorCallback(payload);
      }
    });
};

/**
 * export all function
 */
export default {
  postApiCall,
  getApiCall,
  patchApiCall,
  putApiCall,
  deleteApiCall,
  contentApiCall,
  postApiCallForOtp,
  postApiCallChangeLanguage,
  postApiCallWithoutWebInUrl,
  getApiCallWithoutWebInUrl,
  putApiCallWithoutWebInUrl,
  postMultipartFormData
};
