export const Environment = 
{ 
    GRAPHQL_URL : 'http://admin.parlercrypto.com/graphql',
    STRAPI_URL :  'http://admin.parlercrypto.com'
};

// export const Environment = 
// { 
//     GRAPHQL_URL : 'https://api.skillsorted.com/graphql',
//     STRAPI_URL :  'https://api.skillsorted.com'
// };
