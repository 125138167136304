import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import Utils from "../../../utils";
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import axios from "axios";
const sgMail = require('@sendgrid/mail');
sgMail.setApiKey(process.env.SENDGRID_API_KEY);
const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
  t?: any;
  history?: any;
  GetNFTs?: any;
  UpdateNFT?: any;
  UpdateNFTBuyNow?: any;
  nftsList: any;
  updateNFTResult?: any;
}

interface State {
  lang: string;
  training: string;
  name: string;
  username: string,
  email: string;
  emailColor: string;
  emailErrorShow: string;
  message: string;
  formSubmitted: boolean;
  password: string;
  passwordColor: string;
  passwordErrorShow: string;

  subject: string;
  captchaValue: string;
  isLoading: boolean;
  hasNameError: boolean;
  hasEmailError: boolean;
  hasMessageError: boolean;
  hasSubjectError: boolean;
  validate: boolean;
  errorMessage: string;
  showSignupLoading: boolean,
  callResponseError: string;

  error: {
    emailError: string;
    passwordError: string;
  }
}

export class LoginScreen extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      lang: "en",
      training: "Training",
      name: "",

      email: "",
      emailColor: "white",
      emailErrorShow: "",

      message: "",
      formSubmitted: false,
      username: "",
      password: "",
      passwordColor: "white",
      passwordErrorShow: "",

      subject: "",
      captchaValue: "",
      isLoading: false,
      hasNameError: false,
      hasEmailError: false,
      hasMessageError: false,
      hasSubjectError: false,
      validate: false,
      errorMessage: "",
      showSignupLoading: false,
      callResponseError: "",

      error: {
        emailError: "",
        passwordError: ""
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

  }
  onChange(value: any) {
    this.setState({ captchaValue: value });
    console.log("Captcha value:", value)
  }

  toggleValidating(validate: any) {
    this.setState({ validate });
  }


  async handleSubmit(e: any) {
    console.log('enter')
    e.preventDefault();
    this.toggleValidating(true);
    console.log('start')

    const
      {
        name,
        email,
        message,
        subject,
        captchaValue,
        hasNameError,
        hasEmailError,
        hasSubjectError,
        hasMessageError
      } = this.state;

    if (!hasNameError && !hasEmailError && !hasMessageError) {
      console.log('verified')
      const CONTACTUS_MUTATION = gql`mutation SendContactUsEmail($message:String!){
                createConstactus( data:{captcha:"${captchaValue}", subject:"MTM Web Contact Us Email", name:"${name}", email:"${email}", message:$message}){
                  data{
                      id
                      attributes{
                             email
                    name
                    message
                      }
                 
                  }
                }
              }`;


      this.setState({
        isLoading: true
      });

      try {
        console.log('in try')
        const result = await apolloClient.mutate({ mutation: CONTACTUS_MUTATION, variables: { message: message } });
        console.log('this is result', result)
        if (result) {
          this.setState({
            isLoading: false,
            formSubmitted: true
          });
        }

      } catch (ex) {

        this.setState({
          isLoading: false,
          errorMessage: `Error! ${ex}`
        });

      }
    }

    return false;
  }

  sendEmail(e: any) {
    console.log("hi")
    let message = {
      to: `${this.state.email}`,
      from: `${process.env.EMAIL_FORM1}`,
      // from:`zaeemmehmood@gmail.com`,
      subject: "Feed Back",
      html: `<p><strong>Name: </strong> ${this.state.name}</p>
<p><strong>Message: </strong> ${this.state.message}</p>
`
    }
    console.log("++++++++", message)
    sgMail.send(message).then(() => {
      console.log('sent')
    }).catch((err: any) => {
      console.log('this is error', err)
    })
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });

  };

  async logIn(e: any) {
    console.log('handled')
    let { email, password, error } = this.state;
    let formError = Utils.constant.validateForm({ email, password, error });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {
      if (this.state.error.emailError != "" || this.state.error.passwordError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#ed2939", passwordColor: "#ed2939" })
        this.setState({
          emailErrorShow: this.state.error.emailError,
          passwordErrorShow: this.state.error.passwordError,
        })


      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "white", passwordColor: "white" })

      }
    });
    if (!isValid) {
      return false;
    }
    else {
      this.setState({ showSignupLoading: true });
      axios
        .post('http://admin.parlercrypto.com/api/auth/local', {
          identifier: this.state.email,
          password: this.state.password,
        })
        .then((response) => {
          // Handle success.
          console.log('Well done!');
          console.log('User profile', response.data.user);
          window.localStorage.setItem('jwt', response.data.jwt);
          let jwt = window.localStorage.getItem('jwt');
          console.log("+++++++++++", jwt);
          console.log('User token', response.data.jwt);
          window.location.href = '/#/home';
          // let username = window.localStorage.getItem('user');
          // console.log("Username:", username);
          window.localStorage.setItem('username', response.data.user.username);
          console.log("username", response.data.user.username);
        })
        .catch((error) => {
          // Handle error.
          console.log('An error occurred:', error.response);
          this.setState({errorMessage: error.response.data.error.message});
        });
    }
  }

  async componentDidMount() {
    //@ts-ignore
    window.renderCarousal();
    let language = window.localStorage.getItem('lang')
    //@ts-ignore
    this.setState({ lang: language }, () => {
      //@ts-ignore
      window.renderCarousal();
    })
    document.querySelectorAll('.lang_option')
      .forEach(item => {
        item.addEventListener('click', event => {
          if (item) {
            console.log(item.innerHTML.toLocaleLowerCase())
            this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

              //@ts-ignore
              window.renderCarousal();
            });

          }
        });
      })

  }

  render() {

    return (
      <>
        <ApolloProvider client={apolloClient}>
          <React.Fragment>
            <section className="section6" style={{textAlign:"left"}}>
              <div className="container " >
                <div className="row" >
                <form action="/examples/actions/confirmation.php" method="post" style={{backgroundColor: "#eeeeee"}}>
                  <h2>Sign In</h2>
                  {this.state.errorMessage? (<div className="alert alert-danger">{this.state.errorMessage }</div>):<></>}
                  <hr />
                  <div className="form-group col-lg-12">
                  <div className="field-set">
                  <div className="alert alert-danger" id='errorMessage' style={{ display: 'none' }} role="alert">
                            {this.state.callResponseError}
                          </div>
                          {/* <div className='errorr'>{this.state.emailErrorShow}</div> */}
                        </div>
                    <div className="input-group col-md-offset-3 col-md-6">
                      {/* <span className="input-group-addon"><i className="fa fa-envelope"></i></span> */}
                      <label htmlFor="">Email</label>
                      <input type="email"
                        className="form-control form1"
                        name="email"
                        placeholder="Email Address"
                        required
                        id="email"
                        style={{ background: this.state.emailColor }}
                        value={this.state.email}
                        onChange={(e: any) => {
                          this.handleFiledUpdate("email", e.currentTarget.value);
                        }} />
                    </div>
                    <div className='errorr'>{this.state.emailErrorShow}</div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="input-group col-md-offset-3 col-md-6">
                      {/* <span className="input-group-addon"><i className="fa fa-lock"></i></span> */}
                      <label htmlFor="">Password</label>
                      <input type="password"
                        className="form-control form1"
                        name="password"
                        placeholder="Password"
                        required
                        id="password"
                        style={{ background: this.state.passwordColor }}
                        value={this.state.password}
                        onChange={(e: any) => {
                          this.handleFiledUpdate("password", e.currentTarget.value);
                        }} />
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                  <div className="input-group col-md-offset-3 col-md-6">
                    <button type="button"
                      className="btn"
                      onClick={(e: any) => {
                        this.logIn(e)
                      }}>Sign In</button>
                  </div>
                  </div>
                  {/* <hr/> */}
                  <div className="col-lg-12">
                  <p className="text-center"><a style={{ color: "black" }} href="/#/forgot">Forgot Password?</a></p>
                  <hr />
                  <div className="text-center" style={{marginBottom:"10px"}}>Don't have an account? <a style={{ color: "black" }} href="/#/register">Create one</a></div>
                  </div>
                </form>
                </div>
              </div>
            </section>

          </React.Fragment>
        </ApolloProvider>
      </>
    );
  }
}

function mapStateToProps(state: any) {

  return {
    nftsList: state.homeReducer || {},
    updateNFTResult: state.UpdateNFTReducer || {},
  };
}

function mapDispatchToProps(dispatch: Function) {
  return {
  
  }
}

export const LoginrModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(LoginScreen)
);

export const Login = AppLayout(LoginrModule);
