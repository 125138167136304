import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha"
import { Textbox, Textarea } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
const sgMail = require('@sendgrid/mail');
sgMail.setApiKey(process.env.SENDGRID_API_KEY);
const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
}

interface State {
    lang: string;
    training: string;
    name: string;
    email: string;
    message: string;
    formSubmitted: boolean;
    subject: string;
    captchaValue: string;
    isLoading: boolean;
    hasNameError: boolean;
    hasEmailError: boolean;
    hasMessageError: boolean;
    hasSubjectError: boolean;
    validate: boolean;
    errorMessage: string;
    
}

export class ContactusScreen extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            lang: "en",
            training: "Training",
            name: "",
            email: "",
            message: "",
            formSubmitted: false,
            subject: "",
            captchaValue: "",
            isLoading: false,
            hasNameError: false,
            hasEmailError: false,
            hasMessageError: false,
            hasSubjectError: false,
            validate: false,
            errorMessage: ""
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);

    }
    onChange(value: any) {
        this.setState({ captchaValue: value });
        console.log("Captcha value:", value)
    }

    toggleValidating(validate: any) {
        this.setState({ validate });
    }


    async handleSubmit(e: any) {
        console.log('enter')
        e.preventDefault();
        this.toggleValidating(true);
        console.log('start')

        const
            {
                name,
                email,
                message,
                subject,
                captchaValue,
                hasNameError,
                hasEmailError,
                hasSubjectError,
                hasMessageError
            } = this.state;

        if (!hasNameError && !hasEmailError && !hasMessageError) {
            console.log('verified')
            const CONTACTUS_MUTATION = gql`mutation SendContactUsEmail($message:String!){
                createConstactus( data:{captcha:"${captchaValue}", subject:"MTM Web Contact Us Email", name:"${name}", email:"${email}", message:$message}){
                  data{
                      id
                      attributes{
                             email
                    name
                    message
                      }
                 
                  }
                }
              }`;


            this.setState({
                isLoading: true
            });

            try {
                console.log('in try')
                const result = await apolloClient.mutate({ mutation: CONTACTUS_MUTATION, variables: { message: message } });
                console.log('this is result', result)
                if (result) {
                    this.setState({
                        isLoading: false,
                        formSubmitted: true
                    });
                }

            } catch (ex) {

                this.setState({
                    isLoading: false,
                    errorMessage: `Error! ${ex}`
                });

            }
        }

        return false;
    }

 
    handleFiledUpdate = (key: string, value: any) => {
        //@ts-ignore
        this.setState({ [key]: value });

    };

    sendEmail(e: any) {
        this.setState({isLoading:true});
        fetch("http://admin.parlercrypto.com/api/emails", {
            // Adding method type
            method: "POST",

            // Adding body or contents to send
            body: JSON.stringify({
                from: this.state.email,
                to: "info@parlercrypto.com",
                subject: "Parler-Crypto Contact-us",
                message: this.state.message,
                name: this.state.name
            }),

            // Adding headers to the request
            headers: {
                "Content-type": "application/json; charset=UTF-8",
            },
        })
            // Converting to JSON
            .then((response) => response)

            // Displaying results to console
            .then((json) => {
                console.log(json);
                this.setState({isLoading:false, formSubmitted:true});
            });
    }

    async componentDidMount() {
        //@ts-ignore
        window.renderCarousal();
        let language = window.localStorage.getItem('lang')
        //@ts-ignore
        this.setState({ lang: language }, () => {
            //@ts-ignore
            window.renderCarousal();
        })
        document.querySelectorAll('.lang_option')
            .forEach(item => {
                item.addEventListener('click', event => {
                    if (item) {
                        console.log(item.innerHTML.toLocaleLowerCase())
                        this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

                            //@ts-ignore
                            window.renderCarousal();
                        });

                    }
                });
            })

    }

    render() {

        return (
            <>
                <ApolloProvider client={apolloClient}>
                    <React.Fragment>

                        <section id="contact" className="section6">
                            <div className="container">
                                <Query query={gql`
query Conatactus{
    constactuses(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                subheading
                placeholder1
                placeholder2
                placeholder3
                businessheading1
                businessheading2
                hour1
                hour2
                hour3
                address
                email
                phone
                button
                phone2
                email2
                email1
                success_message
            }
        }
     
    }
   

  }`} >
                                    {

                                        //@ts-ignore
                                        (data: any) => {
                                            console.log('data', data)
                                            return <div>
                                                {

                                                    data.data ? (<>
                                                        <div className="row">
                                                            <div className="col-lg-12 text-center">
                                                                <div className="section-title">
                                                                    <h2>{data.data.constactuses.data[0].attributes.heading}</h2>

                                                                    <p>{data.data.constactuses.data[0].attributes.subheading}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">

                                                            {/* <div className="col-md-6 map">
                                                                <iframe src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d22366.553237060027!2d-73.622344!3d45.513719!3m2!1i1024!2i768!4f13.1!2m1!1sCryptoClubBTM!5e0!3m2!1sen!2sus!4v1646921348475!5m2!1sen!2sus" width="100%" height="450" style={{ border: 0 }} allowFullScreen loading="lazy"></iframe>
                                                            </div> */}

                                                            <div className="contact-form">

                                                                {this.state.formSubmitted == false? (
                                                                <form name="sentMessage" id="contactForm" noValidate >
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control" placeholder={data.data.constactuses.data[0].attributes.placeholder1} id="name" required data-validation-required-message="Please enter your name." value={this.state.name}
                                                                                    onChange={(e: any) => {
                                                                                        this.handleFiledUpdate("name", e.currentTarget.value);
                                                                                    }} />
                                                                                <p className="help-block text-danger"></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <input type="email" className="form-control" placeholder={data.data.constactuses.data[0].attributes.placeholder2} id="email" required data-validation-required-message="Please enter your email address."
                                                                                    value={this.state.email}
                                                                                    onChange={(e: any) => {
                                                                                        this.handleFiledUpdate("email", e.currentTarget.value);
                                                                                    }} />
                                                                                <p className="help-block text-danger"></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <textarea className="form-control" placeholder={data.data.constactuses.data[0].attributes.placeholder3} id="message" required data-validation-required-message="Please enter a message."
                                                                                    value={this.state.message}
                                                                                    onChange={(e: any) => {
                                                                                        this.handleFiledUpdate("message", e.currentTarget.value);
                                                                                    }}
                                                                                    style={{resize:"vertical",  height:"150px"}}
                                                                                    ></textarea>
                                                                                <p className="help-block text-danger"></p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="clearfix"></div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div id="success"></div>
                                                                            <button type="button" onClick={(e: any) => {
                                                                                this.sendEmail(e);
                                                                            }} className="btn">{data.data.constactuses.data[0].attributes.button}</button>
                                                                        </div>
                                                                    </div>
                                                                </form>):(<div className="alert alert-success">{data.data.constactuses.data[0].attributes.success_message}</div>)
                                                                }
                                                                <div className="section-text col-md-6">
                                                                    <h4>{data.data.constactuses.data[0].attributes.businessheading1}</h4>
                                                                    <p><i className="fa fa-clock-o"></i> <span className="day">{data.data.constactuses.data[0].attributes.hour1}</span></p>
                                                                    <p><i className="fa fa-clock-o"></i> <span className="day">{data.data.constactuses.data[0].attributes.hour2}</span></p>
                                                                    <p><i className="fa fa-clock-o"></i> <span className="day">{data.data.constactuses.data[0].attributes.hour3}</span></p>

                                                                </div>
                                                                <div className="section-text col-md-6">
                                                                    <h4>{data.data.constactuses.data[0].attributes.businessheading2}</h4>
                                                                    {/* <p>{data.data.constactuses.data[0].attributes.address}</p> */}
                                                                    {/* <p><i className="fa fa-phone"></i> {data.data.constactuses.data[0].attributes.phone}</p> */}
                                                                    {/* <p><i className="fab fa-whatsapp" ></i><a target='blank' href="https://api.whatsapp.com/send/?phone=+15148024572" style={{color:"black"}}> {data.data.constactuses.data[0].attributes.phone2}</a></p> */}
                                                                    <p><i className="fab fa-whatsapp" ></i><a target='blank' href="https://wa.me/+15148024572" style={{color:"black"}}> {data.data.constactuses.data[0].attributes.phone2}</a></p>
                                                                    
                                                                    {/* <p><i className="fa fa-envelope"></i> {data.data.constactuses.data[0].attributes.email}</p> */}
                                                                    {/* <p><i className="fa fa-envelope"></i>{data.data.constactuses.data[0].attributes.email1}</p> */}
                                                                    <p><i className="fa fa-envelope"></i><a target='blank' href="mailto:info@parlercrypto.com"  style={{color:"black"}}> {data.data.constactuses.data[0].attributes.email2}</a></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>) : (<></>)



                                                }

                                            </div>
                                        }
                                    }
                                </Query>

                            </div>
                        </section>
                        <p id="back-top">
                            <a onClick={() => {window.scrollTo(0, 0)}}><i className="fa fa-angle-up"></i></a>
                        </p>
                    </React.Fragment>
                </ApolloProvider>
            </>
        );
    }
}

function mapStateToProps(state: any) {

    return {
        nftsList: state.homeReducer || {},
        updateNFTResult: state.UpdateNFTReducer || {},
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        
    }
}

export const ContactusModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(ContactusScreen)
);

export const Contactus = AppLayout(ContactusModule);
