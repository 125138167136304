import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
import { GetNFTs } from "..";
import { NFTModal } from "../../../modal";
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
import web3Utills from '../../../utils/web3Utills';
import { UpdateNFT, UpdateNFTBuyNow } from "..";

const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
    GetNFTs?: any;
    UpdateNFT?: any;
    UpdateNFTBuyNow?: any;
    nftsList: any;
    updateNFTResult?: any;
}

interface State {
    lang: string;
    training: string;
}

export class EcosystemScreen extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            lang: "en",
            training: "Training"
        };

    }

    async componentDidMount() {
        //@ts-ignore
        window.renderCarousal();
        let language = window.localStorage.getItem('lang')
        //@ts-ignore
        this.setState({ lang: language }, () => {
            //@ts-ignore
            window.renderCarousal();
        })
        document.querySelectorAll('.lang_option')
            .forEach(item => {
                item.addEventListener('click', event => {
                    if (item) {
                        console.log(item.innerHTML.toLocaleLowerCase())
                        this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

                            //@ts-ignore
                            window.renderCarousal();
                        });

                    }
                });
            })

    }

    render() {

        return (
            <>
                <ApolloProvider client={apolloClient}>
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <h1>{this.state.lang == "en" ? en.op11 : fr.op11}</h1>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                {/* <div className="col-md-12 text-center">
                                <div><img src='images/bit11.PNG' className="img-responsive"/></div>
                                </div> */}
                                <div className="col-md-12 text-center video1">
                                    <iframe width="100%" height="450" src="https://www.youtube.com/embed/cWGZ5TxPpW8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>

                            </div>
                        </div>
                        <div className="section3-2" style={{ marginBottom: "50px" }}>
                            <div className="container">
                                <div className="">
                                    <Query query={gql`
query Testing{
    promotions(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                description
                button
                url
            }
        }
     
    }
  

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            <div className="row">
                                                                <div className={this.state.lang == "en" ? "col-md-6" : "col-md-12"}>
                                                                    <div style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? en.url3 : fr.url3} className="img-responsive" /></div>
                                                                    {/* <div style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? en.url3 : fr.url3} className="img-responsive" /></div> */}
                                                                </div>
                                                                <div className = {this.state.lang == "en" ? "col-md-6" : "col-md-12"}>
                                                                    <h2>{data.data.promotions.data[1].attributes.heading}</h2>
                                                                    <p>{data.data.promotions.data[1].attributes.description.split('**')[0]}</p>
                                                                    <p>{data.data.promotions.data[1].attributes.description.split('**')[1]}</p>
                                                                    <p>{data.data.promotions.data[1].attributes.description.split('**')[2]}</p>
                                                                    {/* <a href="#" className="button-cta">{data.data.promotions.data[1].attributes.button}</a> */}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? en.url4 : fr.url4} className="img-responsive" /></div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h2>{data.data.promotions.data[0].attributes.heading}</h2>
                                                                    <p>{data.data.promotions.data[0].attributes.description}</p>
                                                                    {/* <div className="btn-consult">{this.state.lang == "en" ? "" : <button type="button" className="btn" onClick={(e: any) => {window.location.href = '/contactus'}}>{this.state.lang == "en" ? "" : fr.op40}</button>}</div> */}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="col-md-6">
                                                                    <div className="imagesplit2" style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? "" : fr.url5} className="img-responsive" /></div>
                                                                    <div style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? en.url5 : ""} className="img-responsive" /></div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h2>{data.data.promotions.data[3].attributes.heading}</h2>
                                                                    <p>{data.data.promotions.data[3].attributes.description.split('*')[0]}</p>
                                                                    <div className="imagesplit" style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? "" : fr.url5} className="img-responsive" /></div>
                                                                    <p>{data.data.promotions.data[3].attributes.description.split('*')[1]}</p>
                                                                    {/* <p><b>{this.state.lang == "en" ? "" : fr.op36}</b></p> */}
                                                                    <div className="btn-consult">{this.state.lang == "en" ? "" : <button type="button" className="btn" onClick={(e: any) => {window.location.href = '/#/contactus'}}>{this.state.lang == "en" ? "" : fr.op40}</button>}</div>
                                                                    <p><b>{this.state.lang == "en" ? "" : fr.op36}</b></p>
                                                                </div>
                                                                </div>
                                                                <div className="row">
                                                                <div className="col-md-6">
                                                                    <div style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? en.url6 : ""} className="img-responsive" /></div>
                                                                    <div className="imagesplit2" style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? "" : fr.url6} className="img-responsive" /></div>
                                                                    <div className="imagesplit2" style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? "" : fr.url19} className="img-responsive" /></div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <h2>{data.data.promotions.data[2].attributes.heading}</h2>
                                                                    <p>{data.data.promotions.data[2].attributes.description.split('*')[0]}</p>
                                                                    <div className="imagesplit" style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? "" : fr.url6} className="img-responsive" /></div>
                                                                    <p>{data.data.promotions.data[2].attributes.description.split('*')[1]}</p>
                                                                    {/* <p><b>{this.state.lang == "en" ? "" : fr.op37}</b></p> */}
                                                                    <div className="imagesplit" style={{ marginTop: "25px", marginBottom:"25px" }}><img src={this.state.lang == "en" ? "" : fr.url7} className="img-responsive" /></div>
                                                                    <div className="btn-consult">{this.state.lang == "en" ? "" : <button type="button" className="btn" onClick={(e: any) => {window.location.href = '/#/contactus'}}>{this.state.lang == "en" ? "" : fr.op40}</button>}</div>
                                                                    <p><b>{this.state.lang == "en" ? "" : fr.op37}</b></p>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                            <div className="col-md-6">
                                                                    <div style={{ marginTop: "25px" }}><img src={this.state.lang == "en" ? "" : fr.url8} className="img-responsive" /></div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                <h2>{data.data.promotions.data[4].attributes.heading}</h2>
                                                                    <p>{data.data.promotions.data[4].attributes.description}</p>
                                                                    <div className="btn-consult">{this.state.lang == "en" ? "" : <button type="button" className="btn" onClick={(e: any) => {window.location.href = '/#/contactus'}}>{this.state.lang == "en" ? "" : fr.op40}</button>}</div>
                                                                    {/* <button type="button" className="btn">{this.state.lang == "en" ? "" : fr.op6}</button> */}
                                                                </div>
                                                            </div>
                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>


                                </div>
                            </div>
                        </div>
                        {/* <div className="section3-2 see2">
                <div className="container"> 
                    <div className="row">
                    <Query query={gql`
query Testing{
    promotions(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                description
                button
                url
            }
        }
     
    }
  

  }`} >
                                    {
                                       
                                        //@ts-ignore
                                    (data:any)=>{
                                      console.log('data',data)
                                        return <div>
                                            {
                                            
                                                data.data?(<>
                                                 <div className="col-md-6">
                                                 <h2>{data.data.promotions.data[1].attributes.heading}</h2>
                            <p>{data.data.promotions.data[1].attributes.description}</p>
                            
                        </div>
                        <div className="col-md-6">
                            <h2>{data.data.promotions.data[0].attributes.heading}</h2>
                            <p>{data.data.promotions.data[0].attributes.description}</p>
                            
                        </div>

                                                </>):(<></>)
                                                  
                                                
                                           
                                            }
                                   
                                        </div>
                                    }
                                    }
                                      </Query>
                       

                    </div>
                </div>
            </div> */}
                        {/* <div className="section4-2">
                <div className="container"> 
                    <div className="row">
                    <Query query={gql`
query Testing{
    promotions(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                description
                button
                url
            }
        }
     
    }
  

  }`} >
                                    {
                                       
                                        //@ts-ignore
                                    (data:any)=>{
                                      console.log('data',data)
                                        return <div>
                                            {
                                            
                                                data.data?(<>
                                        
                        <div className="col-md-6">
                            <h3>{data.data.promotions.data[1].attributes.heading}</h3>
                            <p>{data.data.promotions.data[1].attributes.description}</p>
                            <a href="#" className="button-cta">{data.data.promotions.data[1].attributes.button}</a>
                        </div>
                        <div className="col-md-6 text-center">
                        <h2>{data.data.promotions.data[0].attributes.heading}</h2>
                            <p>{data.data.promotions.data[0].attributes.description}</p>
                            <a href="#" className="button-cta">{data.data.promotions.data[0].attributes.button}</a>
                            </div>

                                                </>):(<></>)
                                                  
                                                
                                           
                                            }
                                   
                                        </div>
                                    }
                                    }
                                      </Query>
                        
                    </div>    
                </div>
            </div>     */}

                        <p id="back-top">
                            <a onClick={() => {window.scrollTo(0, 0)}}><i className="fa fa-angle-up"></i></a>
                        </p>

                    </React.Fragment>
                </ApolloProvider>
            </>
        );
    }
}

function mapStateToProps(state: any) {

    return {
        nftsList: state.homeReducer || {},
        updateNFTResult: state.UpdateNFTReducer || {},
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        GetNFTs: (payload: any, callback: Function) => dispatch(GetNFTs(payload, callback)),
        UpdateNFT: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFT(payload, token, callback)),
        UpdateNFTBuyNow: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFTBuyNow(payload, token, callback)),
    }
}

export const EcosystemModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(EcosystemScreen)
);

export const Ecosystem = AppLayout(EcosystemModule);
