import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
import { GetNFTs } from "..";
import { NFTModal } from "../../../modal";
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
import web3Utills from '../../../utils/web3Utills';
import { UpdateNFT, UpdateNFTBuyNow } from "..";

const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
    GetNFTs?: any;
    UpdateNFT?: any;
    UpdateNFTBuyNow?: any;
    nftsList: any;
    updateNFTResult?: any;
}

interface State {
    lang: string;
    training: string;
}

export class CryptoScreen extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            lang: "en",
            training: "Training"
        };

    }

    async componentDidMount() {
        //@ts-ignore
        window.renderCarousal();
        let language = window.localStorage.getItem('lang')
        //@ts-ignore
        this.setState({ lang: language }, () => {
            //@ts-ignore
            window.renderCarousal();
        })
        document.querySelectorAll('.lang_option')
            .forEach(item => {
                item.addEventListener('click', event => {
                    if (item) {
                        console.log(item.innerHTML.toLocaleLowerCase())
                        this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

                            //@ts-ignore
                            window.renderCarousal();
                        });

                    }
                });
            })

    }

    render() {

        return (
            <>
                <ApolloProvider client={apolloClient}>
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <h1>{this.state.lang == "en" ? en.op9 : fr.op9}</h1>
                                </div>
                                <div className="col-md-4 course duration-button" >
                                    <h4>Durée 15 h</h4>
                                </div>
                                {/* <div className="col-md-4"></div> */}
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 text-center video1">
                                    <iframe width="100%" height="450" src="https://www.youtube.com/embed/Qi3GOm1MhN0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>

                            </div>
                        </div>
                        {/* <div className="section3-2">
                            <div className="container">
                                <div className="row">
                                    <Query query={gql`
query Testing{
    promotions(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                description
                button
                url
            }
        }
     
    }
  

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            <div className="col-md-6">
                                                                <h2>{data.data.promotions.data[1].attributes.heading}</h2>
                                                                <p>{data.data.promotions.data[1].attributes.description}</p>
                                                                <a href="#" className="button-cta">{data.data.promotions.data[1].attributes.button}</a>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h2>{data.data.promotions.data[0].attributes.heading}</h2>
                                                                <p>{data.data.promotions.data[0].attributes.description}</p>
                                                                <a href="#" className="button-cta">{data.data.promotions.data[0].attributes.button}</a>
                                                            </div>

                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>


                                </div>
                            </div>
                        </div> */}
                        <section className="section3-2">
                            <div className="container">
                                <div className="row">
                                    <Query query={gql`
query Crypto{
    cryptos(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                h1
                d1
                l1
                l2
                l3
                h2
                d2
                l4
                l5
                l6
                l7
                l8
                l9
                l10
                l11
                l12
                l13
            }
        }
     
    }
  

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            <div className="col-md-6">
                                                            <div style={{marginTop:"25px"}}><img src="images/crypto.jpeg" className="img-responsive cryptoimg"  style={{height:"540px"}}/></div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h2>{data.data.cryptos.data[0].attributes.h1}</h2>
                                                                <p>{data.data.cryptos.data[0].attributes.d1}</p>
                                                                <ul className="lister" style={{ listStyle: "none" }}>
                                                                    <li>{data.data.cryptos.data[0].attributes.l1}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l2}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l3}</li>
                                                                </ul>
                                                                {/* <a href="#" className="button-cta">SEE ALL SERVICES</a> */}
                                                            </div>
                                                            {/* <div className="col-md-6">
                                                                <h2>{data.data.cryptos.data[0].attributes.h2}</h2>
                                                                <ul className="lister" style={{ listStyle: "none" }}>
                                                                    <li>{data.data.cryptos.data[0].attributes.l4}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l5}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l6}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l7}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l8}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l9}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l10}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l11}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l12}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l13}</li>
                                                                </ul>
                                                                <p>{data.data.cryptos.data[0].attributes.d2}</p>
                                                                
                                                            </div> */}
                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>
                                    {/* <div className="col-md-6">
                                        <h2>Cryptomonnaie</h2>
                                        <p>C’est L’actualité de cette dernière décennie et qui va révolutionner votre porte- feuille et notre
                                            monde de LA FINANCE TRADITIONNELLE. Le Bitcoin a vu le jour en 2009 et est la première
                                            cryptomonnaie, qui n'est dirigée par personne : transaction directe, peu importe où vous vous
                                            trouvé dans le monde et de façon sécuritaire, sans aucune autorité centrale ou intermédiaire.
                                            Ce cours est le plus complet. Il va vous donner les moyens et la méthodologie de comprendre
                                            comment fonctionnent les cryptomonnaies, acheter des Bitcoins et toutes les autres
                                            disponibles sur le marché, trader, vendre et sécuriser vos actifs.<br />Découvrez et apprenez les cryptomonnaies avec un formateur reconnu et d’expérience dans ce
                                            domaine</p>
                                        <ol>
                                            <li>Qui maîtrise le sujet depuis le début des cryptomonnaies (2009) </li>
                                            <li>Qui investit activement</li>
                                            <li>Qui participe à la blockchain</li>
                                        </ol>
                                        <a href="#" className="button-cta">SEE ALL SERVICES</a>
                                    </div>
                                    <div className="col-md-6">
                                    <h2>Ce cours va vous permettre de :</h2>
                                        <ul>
                                            <li>Comprendre les cryptomonnaies et découvrir cette nouvelle technol</li>
                                            <li>Comprendre la finance décentralisé (DEFI)</li>
                                            <li>Connaître les différences et les spécificités de chaque cryptomonnaie avant d'acheter</li>
                                            <li>Acheter des cryptomonnaies de façon sécuritaire</li>
                                            <li>Découvrir les outils et autres informations du monde de la cryptomonnaie</li>
                                            <li>Découvrir les différentes banques 2.0 du futur</li>
                                            <li>Apprendre à Sécuriser vos cryptomonnaies</li>
                                            <li>Stratégies des marchés cryptomonnaies</li>
                                            <li>Conseils stratégiques des cryptomonnaies et comment en tirer grand profit.</li>
                                            <li>Responsabilités fiscales</li>
                                        </ul>
                                        <p>Ce cours vous donnera toutes les clés pour investir sereinement et pouvoir prendre la parole
                                            sur ce sujet tout en vous permettant d’assurer et sécuriser votre retraite.</p>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                        <section className="section3-2 see">
                            <div className="container">
                                <div className="row">
                                    <Query query={gql`
query Crypto{
    cryptos(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                h1
                d1
                l1
                l2
                l3
                h2
                d2
                l4
                l5
                l6
                l7
                l8
                l9
                l10
                l11
                l12
                l13
                h3
                l14
                l15
                l16
                l17
            }
        }
     
    }
  

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            {/* <div className="col-md-6">
                                                            <div><img src="images/blog-image1.png" className="img-responsive" /></div>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h2>{data.data.cryptos.data[0].attributes.h1}</h2>
                                                                <p>{data.data.cryptos.data[0].attributes.d1}</p>
                                                                <ul className="lister" style={{ listStyle: "none" }}>
                                                                    <li>{data.data.cryptos.data[0].attributes.l1}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l2}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l3}</li>
                                                                </ul>
                                                                
                                                            </div> */}
                                                            <div className="col-md-6">
                                                                <h2>{data.data.cryptos.data[0].attributes.h2}</h2>
                                                                <ul className="lister" style={{ listStyle: "none" }}>
                                                                    <li>{data.data.cryptos.data[0].attributes.l4}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l5}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l6}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l7}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l8}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l9}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l10}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l11}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l12}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l13}</li>
                                                                </ul>
                                                                <p>{data.data.cryptos.data[0].attributes.d2}</p>
                                                                
                                                            </div>
                                                            <div className="col-md-6">
                                                                <h2>{data.data.cryptos.data[0].attributes.h3}</h2>
                                                                <ul className="lister" style={{ listStyle: "none" }}>
                                                                    <li>{data.data.cryptos.data[0].attributes.l14}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l15}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l16}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l17}</li>
                                                                </ul>
                                                                {/* <a href="#" className="button-cta">SEE ALL SERVICES</a> */}
                                                            </div>
                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>
                                    {/* <div className="col-md-6">
                                        <h2>Cryptomonnaie</h2>
                                        <p>C’est L’actualité de cette dernière décennie et qui va révolutionner votre porte- feuille et notre
                                            monde de LA FINANCE TRADITIONNELLE. Le Bitcoin a vu le jour en 2009 et est la première
                                            cryptomonnaie, qui n'est dirigée par personne : transaction directe, peu importe où vous vous
                                            trouvé dans le monde et de façon sécuritaire, sans aucune autorité centrale ou intermédiaire.
                                            Ce cours est le plus complet. Il va vous donner les moyens et la méthodologie de comprendre
                                            comment fonctionnent les cryptomonnaies, acheter des Bitcoins et toutes les autres
                                            disponibles sur le marché, trader, vendre et sécuriser vos actifs.<br />Découvrez et apprenez les cryptomonnaies avec un formateur reconnu et d’expérience dans ce
                                            domaine</p>
                                        <ol>
                                            <li>Qui maîtrise le sujet depuis le début des cryptomonnaies (2009) </li>
                                            <li>Qui investit activement</li>
                                            <li>Qui participe à la blockchain</li>
                                        </ol>
                                        <a href="#" className="button-cta">SEE ALL SERVICES</a>
                                    </div>
                                    <div className="col-md-6">
                                    <h2>Ce cours va vous permettre de :</h2>
                                        <ul>
                                            <li>Comprendre les cryptomonnaies et découvrir cette nouvelle technol</li>
                                            <li>Comprendre la finance décentralisé (DEFI)</li>
                                            <li>Connaître les différences et les spécificités de chaque cryptomonnaie avant d'acheter</li>
                                            <li>Acheter des cryptomonnaies de façon sécuritaire</li>
                                            <li>Découvrir les outils et autres informations du monde de la cryptomonnaie</li>
                                            <li>Découvrir les différentes banques 2.0 du futur</li>
                                            <li>Apprendre à Sécuriser vos cryptomonnaies</li>
                                            <li>Stratégies des marchés cryptomonnaies</li>
                                            <li>Conseils stratégiques des cryptomonnaies et comment en tirer grand profit.</li>
                                            <li>Responsabilités fiscales</li>
                                        </ul>
                                        <p>Ce cours vous donnera toutes les clés pour investir sereinement et pouvoir prendre la parole
                                            sur ce sujet tout en vous permettant d’assurer et sécuriser votre retraite.</p>
                                    </div> */}
                                </div>
                            </div>
                        </section>
                        {/* <section className="section3-2 see">
                            <div className="container">
                                <div className="row">
                                    <Query query={gql`
query Crypto{
    cryptos(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                h3
                l14
                l15
                l16
                l17
            }
        }
     
    }
  

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            <div className="col-lg-12">
                                                                <h2>{data.data.cryptos.data[0].attributes.h3}</h2>
                                                                <ul className="lister" style={{ listStyle: "none" }}>
                                                                    <li>{data.data.cryptos.data[0].attributes.l14}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l15}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l16}</li>
                                                                    <li>{data.data.cryptos.data[0].attributes.l17}</li>
                                                                </ul>
                                                                
                                                            </div>
                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>
                                    
                                </div>
                            </div>
                        </section> */}

                        <p id="back-top">
                            <a onClick={() => {window.scrollTo(0, 0)}}><i className="fa fa-angle-up"></i></a>
                        </p>
                    </React.Fragment>
                </ApolloProvider>
            </>
        );
    }
}

function mapStateToProps(state: any) {

    return {
        nftsList: state.homeReducer || {},
        updateNFTResult: state.UpdateNFTReducer || {},
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        GetNFTs: (payload: any, callback: Function) => dispatch(GetNFTs(payload, callback)),
        UpdateNFT: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFT(payload, token, callback)),
        UpdateNFTBuyNow: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFTBuyNow(payload, token, callback)),
    }
}

export const CryptoModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(CryptoScreen)
);

export const Crypto = AppLayout(CryptoModule);
