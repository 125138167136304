import React, { useEffect } from "react";
import { AuthRoute, PrivateRoute, AuthRouteRecruiter } from "./route";
import { HashRouter, Route, Switch, useLocation } from "react-router-dom";
import {
  // Login,
  // Forgot,
  // Reset,
  // Signup,
  ResetSuccess,
  // Unsubscribe,
} from "../modules/auth";
import { Success } from "../modules/auth/screen/success.screen";
import { Home } from "../modules/home/screen";
import { Signin } from "../modules/auth/screen";
import { Signup } from "../modules/auth/screen";
import { Trainings } from "../modules/trainings";
import { Contactus } from "../modules/contactus";
import { WhoWeAre } from "../modules/whoweare";
import { Crypto } from "../modules/crypto";
import { Blockchain } from "../modules/blockchain";
import { Ecosystem } from "../modules/ecosystem";
import { Topics } from "../modules/topics";
import { Faq } from "../modules/faq";
import {Register} from "../modules/register";
import {Services} from "../modules/services";
import {Login} from "../modules/login";
import {Payment} from "../modules/payment";
import {ForgotPassword} from "../modules/forgotpassword";
import {Reset} from "../modules/reset";


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Router() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Switch>

        <Route exact path="/forgot" component={ForgotPassword} />
        <Route exact path="/reset" component={Reset} />
        <Route exact path="/reset-success" component={ResetSuccess} />
        <Route exact path="/signup" component={Signup} />
        <Route exact path="/success" component={Success}/>
        
        <Route exact path="/signin" component={Signin} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/trainings" component={Trainings} />

        <Route exact path="/services" component={Services} />

         <Route exact path="/contactus" component={Contactus} />
          <Route exact path="/whoweare" component={WhoWeAre} />
          <Route exact path="/payment" component={Payment} />

          <Route exact path="/crypto" component={Crypto} />
           <Route exact path="/blockchaineducation" component={Blockchain} />
            <Route exact path="/blockchainconsultation" component={Ecosystem} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/topics" component={Topics} />
        <Route exact path="/faq" component={Faq} />
        <Route exact path="/" component={Home} />
      



      </Switch>
    </HashRouter>
  );
}

export default Router;
