import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
import { GetNFTs } from "..";
import { NFTModal } from "../../../modal";
import web3Utills from '../../../utils/web3Utills';
import { UpdateNFT, UpdateNFTBuyNow } from "..";

const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
    GetNFTs?: any;
    UpdateNFT?: any;
    UpdateNFTBuyNow?: any;
    nftsList: any;
    updateNFTResult?: any;
}

interface State {
    lang: string;
    training: string;
}

export class PaymentScreen extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            lang: "en",
            training: "Training"
        };

    }

    async componentDidMount() {

        //@ts-ignore
        window.renderCarousal();
        let language = window.localStorage.getItem('lang')
        //@ts-ignore
        this.setState({ lang: language })
        document.querySelectorAll('.lang_option')
            .forEach(item => {
                item.addEventListener('click', event => {
                    if (item) {
                        console.log(item.innerHTML.toLocaleLowerCase())
                        this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" });
                    }
                });
            })

    }

    render() {

        return (
            <>
                <ApolloProvider client={apolloClient}>
                    <React.Fragment>
                        <section className="page">
                            <div className="container">
                                <div className="row">
                                    <Query query={gql`
query Payment{
    payments(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                h1
                d1
                h2
                d2
                h3
                d3
            }
        }
     
    }
   

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            <div className="col-md-8">
                                                                <h2>{data.data.payments.data[0].attributes.h1}</h2>
                                                                <p><strong>{data.data.payments.data[0].attributes.d1}</strong></p>
                                                                <h4>{data.data.payments.data[0].attributes.h2}</h4>
                                                                <p>{data.data.payments.data[0].attributes.d2}</p>
                                                                <h4>{data.data.payments.data[0].attributes.h3}</h4>
                                                                <p>{data.data.payments.data[0].attributes.d3}</p>
                                                            </div>
                                                            <div className="col-md-4"><img src="images/books.jpg" className="img-responsive" alt="" /></div>
                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>
                                    {/* <div className="col-md-8">
                                        <h2>PAYMENT = PAIEMENT :</h2>
                                        <p><strong>Visa, MasterCard, Virement bancaire et Cash, orange money, Mtn money, BTC, ETH</strong></p>
                                        <h2>Blockchain	Technologies	: Business	Innovation	and	Application : </h2>
                                        <p><strong>1 550. 000 FCFA</strong>(Vous	revient gratuitement car	retour	sur	investissement	rapide	une	fois	le	cours	bien	compris	et	mis	en	application dans	son	secteur	d’activité	ou	autres)</p>
                                        <h2>Cryptocurrencies full courses :</h2>
                                        <p><strong>150 000 FCFA/ 250$</strong>(Vous revient gratuitement car nos
                                            stratégies apprises vous permettront de récupérer ce montant en 6 mois maximum et dans
                                            certains cas en 1 mois)</p>
                                    </div>
                                    <div className="col-md-4"><img src="images/books.jpg" className="img-responsive" alt="" /></div> */}
                                </div>
                            </div>
                        </section>
                        <p id="back-top">
                            <a onClick={() => {window.scrollTo(0, 0)}}><i className="fa fa-angle-up"></i></a>
                        </p>
                    </React.Fragment>
                </ApolloProvider>
            </>
        );
    }
}

function mapStateToProps(state: any) {

    return {
        nftsList: state.homeReducer || {},
        updateNFTResult: state.UpdateNFTReducer || {},
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        GetNFTs: (payload: any, callback: Function) => dispatch(GetNFTs(payload, callback)),
        UpdateNFT: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFT(payload, token, callback)),
        UpdateNFTBuyNow: (payload: NFTModal, token: string, callback: Function) => dispatch(UpdateNFTBuyNow(payload, token, callback)),
    }
}

export const PaymentModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(PaymentScreen)
);

export const Payment = AppLayout(PaymentModule);
