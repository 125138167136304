import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
 
 

const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
    t?: any;
    history?: any;
}

interface State {
    lang: string;
    training: string;
    email: string;
    message: string;
    name: string;
    captchaValue: string;
    formSubmitted: boolean;
    isLoading:boolean;

}

export class HomeScreen extends PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            lang: "en",
            training: "Training",

            email: "",
            message: "",
            name: "",
            captchaValue: "",
            formSubmitted:false,
            isLoading:false
        };
        // this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    }

    


onChange(value: any) {
    this.setState({ captchaValue: value });
    console.log("Captcha value:", value)
}

handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });

};

sendEmail(e: any) {
    fetch("http://admin.parlercrypto.com/api/emails", {
        // Adding method type
        method: "POST",

        // Adding body or contents to send
        body: JSON.stringify({
            from: this.state.email,
            to: "info@parlercrypto.com",
            subject: "Parler-Crypto Contact-us",
            message: this.state.message,
            name: this.state.name
        }),

        // Adding headers to the request
        headers: {
            "Content-type": "application/json; charset=UTF-8",
        },
    })
        // Converting to JSON
        .then((response) => response)

        // Displaying results to console
        .then((json) => {
                console.log(json);
                this.setState({isLoading:false, formSubmitted:true});
            }
        );
}

    async componentDidMount() {
    //@ts-ignore
    window.renderCarousal();
    let language = window.localStorage.getItem('lang') == null ? 'en' : window.localStorage.getItem('lang')
    console.log("lang check", language);
    //@ts-ignore
    this.setState({ lang: language }, () => {
        //@ts-ignore
        window.renderCarousal();
    })
    document.querySelectorAll('.lang_option')
        .forEach(item => {
            item.addEventListener('click', event => {
                if (item) {
                    console.log(item.innerHTML.toLocaleLowerCase())
                    this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

                        //@ts-ignore
                        window.renderCarousal();
                    });

                }
            });
        })

}

render() {

    return (
        <>
            <ApolloProvider client={apolloClient}>
                <React.Fragment>
                    <header>
                        <div className="container-fluid">
                            <div className="slider-container">
                                <div className="owl-slider owl-carousel2 ">
                                    <div className="item" >
                                        <div className="owl-slider-item">
                                            <img src={en.url1} className="img-responsive image" alt="portfolio" />
                                            <div className="intro-text">
                                                <div className="intro-heading">{this.state.lang == "en" ? en.mainheading2 : fr.mainheading2}</div>
                                                <div className="intro-lead-in" style={{ background: "#100e0e8c" }}>{this.state.lang == "en" ? en.subheading : fr.subheading2}<br />{this.state.lang == "en" ? en.subheading2 : fr.subheading}</div>
                                                <div className="btn-mb-center"><a href="/#/contactus" className="btn btn1 butn2">{this.state.lang == "en" ? en.op40 : fr.op40}</a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item" >
                                        <div className="owl-slider-item">
                                            <img src={en.url2} className="img-responsive image" alt="portfolio" />
                                            <div className="intro-text">
                                                <div className="intro-heading">{this.state.lang == "en" ? en.mainheading2 : fr.mainheading2}</div>
                                                <div className="intro-lead-in" style={{ background: "#100e0e8c" }}>{this.state.lang == "en" ? en.subheading : fr.subheading2}<br />{this.state.lang == "en" ? en.subheading2 : fr.subheading}</div>
                                                <div className="btn-mb-center"><a href="/#/contactus" className="btn btn1 butn2">{this.state.lang == "en" ? en.op40 : fr.op40}</a></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </header>
                    <section id="about" className="section1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-2">	</div>
                                <div className="col-lg-8 text-center">
                                    <Query query={gql`
query Experty{
    experties(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                description
            }
        }
     
    }
    

  }`} >
                                        {

                                            //@ts-ignore
                                            (data: any) => {
                                                console.log('data', data)
                                                return <div>
                                                    {

                                                        data.data ? (<>
                                                            <h2>{data.data.experties.data[0].attributes.heading}</h2>
                                                            <p>{data.data.experties.data[0].attributes.description}</p>
                                                        </>) : (<></>)



                                                    }

                                                </div>
                                            }
                                        }
                                    </Query>
                                </div>
                                <div className="col-lg-2">	</div>
                            </div>
                            <div className="row">

                                <Query query={gql`
query Expertytype{
    expertytypes(locale: "${this.state.lang}"){
        data{
            id
            attributes{
heading                description
button
                url
            }
        }
     
    }
    

  }`} >
                                    {

                                        //@ts-ignore
                                        (data: any) => {
                                            console.log('data', data)
                                            return <>

                                                {

                                                    data.data ? (
                                                        <>

                                                            {

                                                                data.data.expertytypes.data.map((item: any) => {
                                                                    // <p>
                                                                    // {
                                                                    //     item.attributes.name
                                                                    // }
                                                                    // </p>
                                                                    return <div className="col-md-4 text-center">
                                                                        <div className="skill-box">
                                                                            <div><img src={item.attributes.url} className="img-responsive" /></div>
                                                                            <h3>{item.attributes.heading}</h3>
                                                                            <p>{item.attributes.description}</p>
                                                                            <div><a href="#">{item.attributes.button}</a></div>


                                                                        </div>
                                                                    </div>
                                                                })


                                                            }
                                                        </>) : (<></>)



                                                }




                                            </>


                                        }
                                    }
                                </Query>
 
                            </div>
                        </div>

                    </section>

                    <section className="section2">
                        <div className="container-fluid">

                            <Query query={gql`
query Training{
    trainings(locale: "${this.state.lang}"){
        data{
            id
            attributes{
               h1
               h2
               h3
               h4
               h5
               h6
               h7
               h8
               d1
               d2
               d3
               d4
               d5
               l1
               l2
               l3
               l4
               l5
               l6
               d8
            }
        }
     
    }
  

  }`} >
                                {

                                    //@ts-ignore
                                    (data: any) => {
                                        console.log('data', data)
                                        return <div style={{display:"none"}}>
                                            {

                                                data.data ? (<>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <h2>{data.data.trainings.data[0].attributes.h1}</h2>
                                                        </div>
                                                    </div>



                                                    <div className="row">
                                                        {

                                                            data.data.trainings.data.map((item: any) => (
                                                                <div className="col-md-6" key={item.id}>

                                                                    <h3>
                                                                        {item.attributes.d1}
                                                                    </h3>

                                                                    <p>
                                                                        {item.attributes.d2}
                                                                    </p>


                                                                    <div className="row">
                                                                        <div className="col-md-4 text-center training-icons">
                                                                            <a href="#"> <div><img src={item.attributes.l1} /></div>
                                                                                <div>  {item.attributes.op1}</div>
                                                                            </a>

                                                                        </div>


                                                                        <div className="col-md-4 text-center training-icons">
                                                                            <a href="#"> <div><img src={item.attributes.opimg2} /></div>
                                                                                <div>  {item.attributes.op2}</div>
                                                                            </a>

                                                                        </div>


                                                                        <div className="col-md-4 text-center training-icons">
                                                                            <a href="#"> <div><img src={item.attributes.opimg3} /></div>
                                                                                <div>  {item.attributes.op3}</div>
                                                                            </a>

                                                                        </div>

                                                                    </div>








                                                                </div>



                                                            ))
                                                        }

                                                        <div className="col-md-6 text-center video1">
                                                            <iframe width="100%" height="450" src="https://www.youtube.com/embed/WWnAGoLNzLE" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        </div>  </div>     </>) : (<></>)
                                            }

                                        </div>
                                    }
                                }
                            </Query>
                            <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="col-md-6">
                                    <iframe width="100%" height="450" src="https://www.youtube.com/embed/cWGZ5TxPpW8" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                    <div className="col-md-6">
                                    <iframe width="100%" height="450" src="https://www.youtube.com/embed/Qi3GOm1MhN0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                                </div>
                                </div>
                                {/* <div className="col-md-6 text-center video1">
                                    <iframe width="100%" height="450" src="https://www.youtube.com/embed/Qi3GOm1MhN0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div> */}
                            {/* <div className="owl-partners owl-carousel3">

                                <div className=" item">
                                    <div className="training-box">
                                        <div><img src="images/training-image1.png" className="img-responsive" /></div>
                                        <h4><a href="#">{this.state.lang == "en" ? en.formationheading1 : fr.formationheading1}</a></h4>
                                        <p>{this.state.lang == "en" ? en.formationdescription1 : fr.formationdescription1}</p>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="training-box">
                                        <div><img src="images/training-image2.png" className="img-responsive" /></div>
                                        <h4><a href="#"> {this.state.lang == "en" ? en.formationheading2 : fr.formationheading2}</a></h4>
                                        <p>{this.state.lang == "en" ? en.formationdescription2 : fr.formationdescription2}</p>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="training-box">
                                        <div><img src="images/training-image3.png" className="img-responsive" /></div>
                                        <h4><a href="#">{this.state.lang == "en" ? en.formationheading3 : fr.formationheading3}</a></h4>
                                        <p>{this.state.lang == "en" ? en.formationdescription3 : fr.formationdescription3}</p>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="training-box">
                                        <div><img src="images/training-image4.png" className="img-responsive" /></div>
                                        <h4><a href="#">{this.state.lang == "en" ? en.formationheading4 : fr.formationheading4}</a></h4>
                                        <p>{this.state.lang == "en" ? en.formationdescription4 : fr.formationdescription4}</p>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="training-box">
                                        <div><img src="images/training-image1.png" className="img-responsive" /></div>
                                        <h4><a href="#">{this.state.lang == "en" ? en.formationheading1 : fr.formationheading1}</a></h4>
                                        <p>{this.state.lang == "en" ? en.formationdescription1 : fr.formationdescription1}</p>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="training-box">
                                        <div><img src="images/training-image2.png" className="img-responsive" /></div>
                                        <h4><a href="#"> {this.state.lang == "en" ? en.formationheading2 : fr.formationheading2}</a></h4>
                                        <p>{this.state.lang == "en" ? en.formationdescription2 : fr.formationdescription2}</p>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="training-box">
                                        <div><img src="images/training-image3.png" className="img-responsive" /></div>
                                        <h4><a href="#">{this.state.lang == "en" ? en.formationheading3 : fr.formationheading3}</a></h4>
                                        <p>{this.state.lang == "en" ? en.formationdescription3 : fr.formationdescription3}</p>
                                    </div>
                                </div>

                                <div className="item">
                                    <div className="training-box">
                                        <div><img src="images/training-image4.png" className="img-responsive" /></div>
                                        <h4><a href="#">{this.state.lang == "en" ? en.formationheading4 : fr.formationheading4}</a></h4>
                                        <p>{this.state.lang == "en" ? en.formationdescription4 : fr.formationdescription4}</p>
                                    </div>
                                </div>

                            </div> */}
                        </div>
                    </section>

                    <section className="section-stats short-section stats-bar">
                        <div className="container text-center">
                            <div className="row">
                                <Query query={gql`
query Value{
    values(locale: "${this.state.lang}"){
        data{
            id
            attributes{
heading                number
button
                url
            }
        }
     
    }
    

  }`} >
                                    {

                                        //@ts-ignore
                                        (data: any) => {
                                            console.log('data', data)
                                            return <>

                                                {

                                                    data.data ? (
                                                        <>

                                                            {

                                                                data.data.values.data.map((item: any) => {
                                                                    // <p>
                                                                    // {
                                                                    //     item.attributes.name
                                                                    // }
                                                                    // </p>
                                                                    return <div className="col-md-4 mb-sm-30">
                                                                        <div className="counter-item">
                                                                            <div><img src={item.attributes.url} className="img-responsive" /></div>
                                                                            <h2 className="stat-number" data-n={item.attributes.number}>0</h2>
                                                                            <h6>{item.attributes.heading}</h6>
                                                                            <div><a href="#">{item.attributes.button}</a></div>
                                                                        </div>
                                                                    </div>
                                                                })


                                                            }
                                                        </>) : (<></>)



                                                }




                                            </>


                                        }
                                    }
                                </Query>
                                

                            </div>
                        </div>
                    </section>
                    


                    <section className="section3">
                        <div className="container">
                            <div className="row">
                                <Query query={gql`
query Blockchain{
    blockchains(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                h1
                d1
                d2
                d3
            }
        }
     
    }
  

  }`} >
                                    {

                                        //@ts-ignore
                                        (data: any) => {
                                            console.log('data', data)
                                            return <div>
                                                {

                                                    data.data ? (<>
                                                        <div className="col-md-6">
                                                            <img src={data.data.blockchains.data[2].attributes.d3} className="img-responsive" />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h2>{data.data.blockchains.data[2].attributes.h1}</h2>
                                                            {/* <p>{data.data.blockchains.data[2].attributes.d1}</p> */}
                                                            <p>{this.state.lang == "en" ? en.bpara : fr.bpara}</p>
                                                            <a href="/#/contactus" className="button-cta">{this.state.lang == "en" ? en.op40 : fr.op40}</a>
                                                        </div>

                                                    </>) : (<></>)



                                                }

                                            </div>
                                        }
                                    }
                                </Query>


                            </div>
                        </div>
                    </section>
                    <section className="section3 seccolor">
                        <div className="container">
                            <div className="row">
                                <Query query={gql`
query Blockchain{
    blockchains(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                h1
                d1
                d2
                d3
            }
        }
     
    }
  

  }`} >
                                    {

                                        //@ts-ignore
                                        (data: any) => {
                                            console.log('data', data)
                                            return <div>
                                                {

                                                    data.data ? (<>

                                                        <div className="col-md-6">
                                                            <h2>{data.data.blockchains.data[1].attributes.h1}</h2>
                                                            <p className="para">{data.data.blockchains.data[1].attributes.d1}</p>
                                                            <a href="/#/services" className="button-cta">{data.data.blockchains.data[2].attributes.d2}</a>
                                                        </div>
                                                        <div className="col-md-6 text-center">
                                                            <img src={data.data.blockchains.data[1].attributes.d3} className="img-responsive" />
                                                        </div>

                                                    </>) : (<></>)



                                                }

                                            </div>
                                        }
                                    }
                                </Query>
                                {/* <div className="col-md-6">
                            <h3>Helping clients with research and strategy for their business</h3>
                            <p>Do you want to get a head start on the revolution brought by the new cryptographic currencies? Do you want to make a purchase of bitcoin or other crypto? Neoconomia is here to help you, train you and assist you in your first steps in crypto-economy, and show you the advantages offered by this new decentralized finance!!</p>
                            <a href="#" className="button-cta">See all services</a>
                        </div>
                        <div className="col-md-6 text-center"><img src="images/feature-training-image.png" className="img-responsive" /></div>
                    */}
                            </div>
                        </div>
                    </section>
                    <section className="section5">
                        <div className="container">
                            <h2>{this.state.lang == "en" ? en.sheading : fr.sheading}</h2>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="owl-partners owl-carousel3">
                                        <div className="item">
                                            <div className="training-box-next training-box" >
                                                <div><img src="images/blog-image1.png" className="img-responsive" /></div>
                                                <h4><a href="#">{this.state.lang == "en" ? en.s1 : fr.s1}</a></h4>

                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="training-box-next training-box">
                                                <div><img src="images/blog-image2.png" className="img-responsive" /></div>
                                                <h4><a href="#"> {this.state.lang == "en" ? en.s2 : fr.s2}
                                                </a></h4>

                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="training-box-next training-box" >
                                                <div><img src="images/blog-image3.png" className="img-responsive" /></div>
                                                <h4><a href="#">{this.state.lang == "en" ? en.s3 : fr.s3}</a></h4>

                                            </div>

                                        </div>

                                        <div className="item">
                                            <div className="training-box-next training-box">
                                                <div><img src="images/blog-image1.png" className="img-responsive" /></div>
                                                <h4><a href="#">{this.state.lang == "en" ? en.s4 : fr.s4}</a></h4>

                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="training-box-next training-box">
                                                <div><img src="images/blog-image2.png" className="img-responsive" /></div>
                                                <h4><a href="#"> {this.state.lang == "en" ? en.s2 : fr.s2}
                                                </a></h4>

                                            </div>
                                        </div>

                                        <div className="item">
                                            <div className="training-box-next training-box">
                                                <div><img src="images/blog-image3.png" className="img-responsive" /></div>
                                                <h4><a href="#">{this.state.lang == "en" ? en.s3 : fr.s3}</a></h4>

                                            </div>

                                        </div>

                                    </div>



                                </div>
                            </div>
                        </div>

                    </section>
                    <section id="contact" className="section6">
                        <div className="container">
                            <Query query={gql`
query Conatactus{
    constactuses(locale: "${this.state.lang}"){
        data{
            id
            attributes{
                heading
                subheading
                placeholder1
                placeholder2
                placeholder3
                businessheading1
                businessheading2
                hour1
                hour2
                hour3
                address
                email
                phone
                button
                phone2
                email1
                email2
                success_message
            }
        }
     
    }
   

  }`} >
                                {

                                    //@ts-ignore
                                    (data: any) => {
                                        console.log('data', data)
                                        return <div>
                                            {

                                                data.data ? (<>
                                                    <div className="row">
                                                        <div className="col-lg-12 text-center">
                                                            <div className="section-title">
                                                                <h2>{data.data.constactuses.data[0].attributes.heading}</h2>

                                                                <p>{data.data.constactuses.data[0].attributes.subheading}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">

                                                        {/* <div className="col-md-6 map">
                                                            <iframe src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d22366.553237060027!2d-73.622344!3d45.513719!3m2!1i1024!2i768!4f13.1!2m1!1sCryptoClubBTM!5e0!3m2!1sen!2sus!4v1646921348475!5m2!1sen!2sus" width="100%" height="450" style={{ border: 0 }} allowFullScreen loading="lazy"></iframe>
                                                        </div> */}

                                                        <div className="contact-form">
                                                        {this.state.formSubmitted == false? (
                                                            <form name="sentMessage" id="contactForm" noValidate>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control" placeholder={data.data.constactuses.data[0].attributes.placeholder1} id="name" required data-validation-required-message="Please enter your name." 
                                                                             onChange={(e: any) => {
                                                                                this.handleFiledUpdate("name", e.currentTarget.value);
                                                                            }}/>
                                                                            <p className="help-block text-danger"></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <input type="email" className="form-control" placeholder={data.data.constactuses.data[0].attributes.placeholder2} id="email" required data-validation-required-message="Please enter your email address." 
                                                                            onChange={(e: any) => {
                                                                                this.handleFiledUpdate("email", e.currentTarget.value);
                                                                            }} />
                                                                            <p className="help-block text-danger"></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                            <textarea className="form-control" placeholder={data.data.constactuses.data[0].attributes.placeholder3} id="message" required data-validation-required-message="Please enter a message."
                                                                            onChange={(e: any) => {
                                                                                this.handleFiledUpdate("message", e.currentTarget.value);
                                                                            }}
                                                                            style={{resize:"vertical", height:"150px"}}></textarea>
                                                                            <p className="help-block text-danger"></p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="clearfix"></div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div id="success"></div>
                                                                        <button type="button" className="btn"
                                                                         onClick={(e: any) => {
                                                                            this.sendEmail(e);
                                                                        }}>{data.data.constactuses.data[0].attributes.button}</button>
                                                                    </div>
                                                                </div>
                                                            </form>):(<div className="alert alert-success">{data.data.constactuses.data[0].attributes.success_message}</div>)
                                                                }
                                                            <div className="section-text col-md-6">
                                                                <h4>{data.data.constactuses.data[0].attributes.businessheading1}</h4>
                                                                <p><i className="fa fa-clock-o"></i> <span className="day">{data.data.constactuses.data[0].attributes.hour1}</span></p>
                                                                <p><i className="fa fa-clock-o"></i> <span className="day">{data.data.constactuses.data[0].attributes.hour2}</span></p>
                                                                <p><i className="fa fa-clock-o"></i> <span className="day">{data.data.constactuses.data[0].attributes.hour3}</span></p>

                                                            </div>
                                                            <div className="section-text col-md-6">
                                                                <h4>{data.data.constactuses.data[0].attributes.businessheading2}</h4>
                                                                {/* <p>{data.data.constactuses.data[0].attributes.address}</p> */}
                                                                {/* <p><i className="fa fa-phone"></i> {data.data.constactuses.data[0].attributes.phone}</p> */}
                                                                <p><i className="fab fa-whatsapp" ></i><a target='blank' href="https://wa.me/+15148024572" style={{color:"black"}}> {data.data.constactuses.data[0].attributes.phone2}</a></p>
                                                                {/* <p><i className="fa fa-envelope"></i> {data.data.constactuses.data[0].attributes.email}</p> */}
                                                                {/* <p><i className="fa fa-envelope"></i>{data.data.constactuses.data[0].attributes.email1}</p> */}
                                                                <p><i className="fa fa-envelope"></i><a target='blank' href="mailto:info@parlercrypto.com"  style={{color:"black"}}> {data.data.constactuses.data[0].attributes.email2}</a></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>) : (<></>)



                                            }

                                        </div>
                                    }
                                }
                            </Query>

                        </div>
                    </section>
                    <p id="back-top">
                        <a onClick={() => {window.scrollTo(0, 0)}}><i className="fa fa-angle-up"></i></a>
                    </p>
                </React.Fragment>
            </ApolloProvider>
        </>
    );
}
}

function mapStateToProps(state: any) {

    return {
        nftsList: state.homeReducer || {},
        updateNFTResult: state.UpdateNFTReducer || {},
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
       
    }
}

export const HomeModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
);

export const Home = AppLayout(HomeModule);
