import React, { PureComponent } from "react";
import OnBoardingBanner from "../component/onboardingleft/onboardingbanner";

import Authlayout from "../../../applayout/authlayout";
import Utils from "../../../utils";
interface Props {}

interface State {
  confirmpassword: string;
  password: string;
  error: {
    confirmpasswordError: string;
    passwordError: string;
  };
}

class ResetScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      confirmpassword: "",
      password: "",
      error: {
        confirmpasswordError: "",
        passwordError: "",
      },
    };
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };

  handleSubmit = () => {
    let { password, confirmpassword, error } = this.state;
    let formError = Utils.constant.validateForm({
      password,
      confirmpassword,
      error,
    });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {});
    if (!isValid) {
      return false;
    } else {
      //   this.doReset()
      // alert("success")
    }
  };

  // doReset = () => {
  //     let { newpassword } = this.state;
  //     //const parsed = qs.parse(this.props.location.search);

  //     let payload = {
  //         password: newpassword,
  //         email: this.props.location.state.email,
  //         user_type: Utils.constant.userType.recruiter,
  //         resetToken: this.props.location.state.resetToken,
  //     }
  //     this.props.doResetPassword(payload, () => {
  //         this.setState({
  //             newpassword: "",
  //             confirmpassword: "",
  //             error: {
  //                 newpasswordError: "",
  //                 confirmpasswordError: "",
  //             }
  //         });
  //         Utils.constant.successMessage('Password Changed Successfully');
  //         setTimeout(()=>{
  //             this.props.history.push('/');
  //         },1000)

  //     })
  // }
  render() {
    return (
    <></>
    );
  }
}

export const Reset = Authlayout(ResetScreen);
