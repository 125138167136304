import React from 'react';
import Header from '../../component/header/header';
import Footer from '../../component/footer/footer';
interface Props {
  history: any;
  doLogout: Function;
}
class LayoutWrapper extends React.Component<Props> {
  render() {
    return (
      <>
        <Header></Header>
        {
          this.props.children
        }
        <Footer></Footer>
      </>
    );
  }
}
export default LayoutWrapper;
