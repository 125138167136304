import Utils from "../../utils";
import { SelectCategoryModel, NFTModal } from "../../modal";

/***************************************Select Category*********************************************/
export function SelectCategory(
  payload: any,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.SelectCategory;
    Utils.api.getApiCall(
      endPoint,
      ``,
      payload,
      function (response: any) {
        if (response.status) {
          let data: SelectCategoryModel = response.data.data;
          dispatch({
            type: Utils.actionName.SELECT_CATEGORY,
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}

/*************************************** Get NFTs Data*********************************************/
export function GetNFTs(
  payload: any,
  callback: Function = function () { }
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.GetNFTs;
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          dispatch({
            type: Utils.actionName.GET_NFTs,
            payload: data,
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
export function UpdateNFT(payload: NFTModal, token: string, callback: Function) {

  return (dispatch: Function, getState: Function) => {

    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.UpdateNFTForSale;

    Utils.api.postApiCallWithoutWebInUrl(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {
          let data: NFTModal = response.data.data;
          console.log(data);
          dispatch({
            type: Utils.actionName.UPDATE_NFT,
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
export function UpdateNFTBuyNow(payload: NFTModal, token: string, callback: Function) {

  return (dispatch: Function, getState: Function) => {

    let hasConnection = Utils.constant.hasConnection();

    if (!hasConnection) {
      return false;
    }

    Utils.constant.handleOverlayLoader(true);

    let endPoint = Utils.endpoints.UpdateNFTBuyNow;

    Utils.api.postApiCallWithoutWebInUrl(
      endPoint,
      payload,
      token,
      function (response: any) {
        if (response.status) {
          let data: NFTModal = response.data.data;
          console.log(data);
          dispatch({
            type: Utils.actionName.UPDATE_NFT,
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}