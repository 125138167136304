import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import axios from "axios";
const queryString = require('query-string');
const sgMail = require('@sendgrid/mail');
sgMail.setApiKey(process.env.SENDGRID_API_KEY);
const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

interface Props {
  t?: any;
  history?: any;
}

interface State {
  lang: string;
  training: string;
  name: string;
  username: string,
  email: string;
  message: string;
  formSubmitted: boolean;
  password: string;
  confirmPassword: string;
  subject: string;
  captchaValue: string;
  isLoading: boolean;
  hasNameError: boolean;
  hasEmailError: boolean;
  hasMessageError: boolean;
  hasSubjectError: boolean;
  validate: boolean;
  errorMessage: string;
}

export class ResetScreen extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      lang: "en",
      training: "Training",
      name: "",
      email: "",
      message: "",
      formSubmitted: false,
      username: "",
      password: "",
      confirmPassword: "",
      subject: "",
      captchaValue: "",
      isLoading: false,
      hasNameError: false,
      hasEmailError: false,
      hasMessageError: false,
      hasSubjectError: false,
      validate: false,
      errorMessage: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

  }
  onChange(value: any) {
    this.setState({ captchaValue: value });
    console.log("Captcha value:", value)
  }

  toggleValidating(validate: any) {
    this.setState({ validate });
  }


  async handleSubmit(e: any) {
    console.log('enter')
    e.preventDefault();
    this.toggleValidating(true);
    console.log('start')

    const
      {
        name,
        email,
        message,
        subject,
        captchaValue,
        hasNameError,
        hasEmailError,
        hasSubjectError,
        hasMessageError
      } = this.state;

    if (!hasNameError && !hasEmailError && !hasMessageError) {
      console.log('verified')
      const CONTACTUS_MUTATION = gql`mutation SendContactUsEmail($message:String!){
                createConstactus( data:{captcha:"${captchaValue}", subject:"MTM Web Contact Us Email", name:"${name}", email:"${email}", message:$message}){
                  data{
                      id
                      attributes{
                             email
                    name
                    message
                      }
                 
                  }
                }
              }`;


      this.setState({
        isLoading: true
      });

      try {
        console.log('in try')
        const result = await apolloClient.mutate({ mutation: CONTACTUS_MUTATION, variables: { message: message } });
        console.log('this is result', result)
        if (result) {
          this.setState({
            isLoading: false,
            formSubmitted: true
          });
        }

      } catch (ex) {

        this.setState({
          isLoading: false,
          errorMessage: `Error! ${ex}`
        });

      }
    }

    return false;
  }

  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });

  };

  async resetPassword(e:any){
     //@ts-ignore
    const parsed = queryString.parse(this.props.location.search);
    console.log(parsed?.code);
 
    axios
    .post('http://admin.parlercrypto.com/api/auth/reset-password', {
    code: parsed?.code,
    password: this.state.password,
    passwordConfirmation: this.state.password
    })
    .then((response) => {
      this.setState({formSubmitted:true});
      setTimeout(() => {
        //@ts-ignore
        window.location.href = "/#/login";
      }, 15000);
      

    })
    .catch((error) => {
      console.log('An error occurred:', error.response.data.error.message);
      this.setState({errorMessage: error.response.data.error.message});
    });
  }

  async componentDidMount() {
    //@ts-ignore
    window.renderCarousal();
    let language = window.localStorage.getItem('lang')
    //@ts-ignore
    this.setState({ lang: language }, () => {
      //@ts-ignore
      window.renderCarousal();
    })
    document.querySelectorAll('.lang_option')
      .forEach(item => {
        item.addEventListener('click', event => {
          if (item) {
            console.log(item.innerHTML.toLocaleLowerCase())
            this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

              //@ts-ignore
              window.renderCarousal();
            });

          }
        });
      })

  }

  render() {

    return (
      <>
        <ApolloProvider client={apolloClient}>
          <React.Fragment>
          <section className="section6">
              <div className="container">
                <div className="row">
                {this.state.formSubmitted == false? ( 
                <form action="#" method="post">
                <div className="text-center col-lg-12">
                  <h2>Reset Password</h2>
                  
                  <p>Please Enter Password and Confirm Password</p>
                  {this.state.errorMessage? (<div className="alert alert-danger">{this.state.errorMessage }</div>):<></>}
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="input-group col-md-offset-3  col-md-6">
                  
                      <label>Password</label>
                      <input type="password" 
                      className="form-control" 
                      name="password" 
                      placeholder="Password" 
                      required 
                      id="password"
                      onChange={(e: any) => {
                        this.handleFiledUpdate("password", e.currentTarget.value);
                      }} />
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="input-group col-md-offset-3  col-md-6">
                  
                      <label>Confirm Password</label>
                      <input type="password" 
                      className="form-control" 
                      name="confirmpassword" 
                      placeholder="Confirm Password" 
                      required 
                      id="confirmpassword"
                      onChange={(e: any) => {
                        this.handleFiledUpdate("confirmpassword", e.currentTarget.value);
                      }} />
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                  <div className="input-group col-md-offset-3 col-md-6">
                  <button type="button" 
                    className="btn"
                    onClick={(e: any) => {
                      this.resetPassword(e)
                    }}>Reset</button>
                  </div>
                  </div>
                  <hr/>
                  <div className="text-center col-lg-12">Don't have an account? <a style={{color:"black"}} href="/#/register">Create one</a></div>
                </form>):(<div className="alert alert-info">Your password has been reset! Redirecting to login ...</div>)}
                
              </div>
              </div>
            </section>
          </React.Fragment>
        </ApolloProvider>
      </>
    );
  }
}

function mapStateToProps(state: any) {

  return {
    nftsList: state.homeReducer || {},
    updateNFTResult: state.UpdateNFTReducer || {},
  };
}

function mapDispatchToProps(dispatch: Function) {
  return {
 
  }
}

export const ResetModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ResetScreen)
);

export const Reset = AppLayout(ResetModule);
