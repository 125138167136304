import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha"
import { Textbox, Textarea } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
// import './style2.css';
import en from '../../../languages/en/en.json';
import fr from '../../../languages/fr/fr.json';
import axios from "axios";
import { useHistory } from "react-router-dom";
const sgMail = require('@sendgrid/mail');
sgMail.setApiKey(process.env.SENDGRID_API_KEY);
const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;
interface Props {
  t?: any;
  history?: any;
  GetNFTs?: any;
  UpdateNFT?: any;
  UpdateNFTBuyNow?: any;
  nftsList: any;
  updateNFTResult?: any;
}

interface State {
  lang: string;
  training: string;
  name: string;
  username: string,
  email: string;
  message: string;
  formSubmitted: boolean;
  password: string;

  subject: string;
  captchaValue: string;
  isLoading: boolean;
  hasNameError: boolean;
  hasEmailError: boolean;
  hasMessageError: boolean;
  hasSubjectError: boolean;
  validate: boolean;
  errorMessage: string;
}

export class ForgotPasswordScreen extends PureComponent<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      lang: "en",
      training: "Training",
      name: "",
      email: "",
      message: "",
      formSubmitted: false,
      username: "",
      password: "",

      subject: "",
      captchaValue: "",
      isLoading: false,
      hasNameError: false,
      hasEmailError: false,
      hasMessageError: false,
      hasSubjectError: false,
      validate: false,
      errorMessage: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

  }
  onChange(value: any) {
    this.setState({ captchaValue: value });
    console.log("Captcha value:", value)
  }

  toggleValidating(validate: any) {
    this.setState({ validate });
  }


  async handleSubmit(e: any) {
    console.log('enter')
    e.preventDefault();
    this.toggleValidating(true);
    console.log('start')

    const
      {
        name,
        email,
        message,
        subject,
        captchaValue,
        hasNameError,
        hasEmailError,
        hasSubjectError,
        hasMessageError
      } = this.state;

    if (!hasNameError && !hasEmailError && !hasMessageError) {
      console.log('verified')
      const CONTACTUS_MUTATION = gql`mutation SendContactUsEmail($message:String!){
                createConstactus( data:{captcha:"${captchaValue}", subject:"MTM Web Contact Us Email", name:"${name}", email:"${email}", message:$message}){
                  data{
                      id
                      attributes{
                             email
                    name
                    message
                      }
                 
                  }
                }
              }`;


      this.setState({
        isLoading: true
      });

      try {
        console.log('in try')
        const result = await apolloClient.mutate({ mutation: CONTACTUS_MUTATION, variables: { message: message } });
        console.log('this is result', result)
        if (result) {
          this.setState({
            isLoading: false,
            formSubmitted: true
          });
        }

      } catch (ex) {

        this.setState({
          isLoading: false,
          errorMessage: `Error! ${ex}`
        });

      }
    }

    return false;
  }

  sendEmail(e: any) {
    console.log("hi")
    let message = {
      to: `${this.state.email}`,
      from: `${process.env.EMAIL_FORM1}`,
      // from:`zaeemmehmood@gmail.com`,
      subject: "Feed Back",
      html: `<p><strong>Name: </strong> ${this.state.name}</p>
<p><strong>Message: </strong> ${this.state.message}</p>
`
    }
    console.log("++++++++", message)
    sgMail.send(message).then(() => {
      console.log('sent')
    }).catch((err: any) => {
      console.log('this is error', err)
    })
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });

  };

  async forgot(e:any){
    axios
    .post('http://admin.parlercrypto.com/api/auth/forgot-password', {
      email: this.state.email // user's email
    })
    .then((response) => {
      this.setState({formSubmitted:true});
      console.log('Your user received an email');
    })
    .catch((error) => {
      console.log('An error occurred:', error.response);
      this.setState({errorMessage: error.response.data.error.message});
    });
  }

  async componentDidMount() {
    //@ts-ignore
    window.renderCarousal();
    let language = window.localStorage.getItem('lang')
    //@ts-ignore
    this.setState({ lang: language }, () => {
      //@ts-ignore
      window.renderCarousal();
    })
    document.querySelectorAll('.lang_option')
      .forEach(item => {
        item.addEventListener('click', event => {
          if (item) {
            console.log(item.innerHTML.toLocaleLowerCase())
            this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

              //@ts-ignore
              window.renderCarousal();
            });

          }
        });
      })

  }

  render() {

    return (
      <>
        <ApolloProvider client={apolloClient}>
          <React.Fragment>
            <section className="section6">
              <div className="container">
                <div className="row">
                {this.state.formSubmitted == false? ( 
                <form action="#" method="post">
                <div className="text-center col-lg-12">
                  <h2>Forgot Password?</h2>
                  
                  <p>Please Enter you Email Address</p>
                  {this.state.errorMessage? (<div className="alert alert-danger">{this.state.errorMessage }</div>):<></>}
                  </div>
                  <div className="form-group col-lg-12">
                    <div className="input-group col-md-offset-3  col-md-6">
                      {/* <span className="input-group-addon"><i className="fa fa-envelope"></i></span> */}
                      <label>Email</label>
                      <input type="email" 
                      className="form-control" 
                      name="email" 
                      placeholder="Email Address" 
                      required 
                      id="email"
                      onChange={(e: any) => {
                        this.handleFiledUpdate("email", e.currentTarget.value);
                      }} />
                    </div>
                  </div>
                  <div className="form-group col-lg-12">
                  <div className="input-group col-md-offset-3 col-md-6">
                    <button type="button" 
                    className="btn"
                    onClick={(e: any) => {
                      this.forgot(e)
                    }}>Submit</button>
                  </div>
                  </div>
                  <hr/>
                  <div className="text-center col-lg-12">Don't have an account? <a style={{color:"black"}} href="/register">Create one</a></div>
                </form>):(<div className="alert alert-info">Please check your email for reset password link.</div>)}
                
              </div>
              </div>
            </section>

          </React.Fragment>
        </ApolloProvider>
      </>
    );
  }
}

function mapStateToProps(state: any) {

  return {
    nftsList: state.homeReducer || {},
    updateNFTResult: state.UpdateNFTReducer || {},
  };
}

function mapDispatchToProps(dispatch: Function) {
  return {
   
  }
}

export const ForgotPasswordModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordScreen)
);

export const ForgotPassword = AppLayout(ForgotPasswordModule);
