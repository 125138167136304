import React, { PureComponent } from "react";
import { connect } from "react-redux";

import AuthLayout from "../../../applayout/authlayout";
import { doLogin } from "..";
import { withTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
import { Link } from 'react-router-dom';
interface Props {
  t: any;
  history?: any;
  doLogin: Function;
  loginResponce?: any;
}
interface State {
  email: string;
  password: string;
  loginError: boolean;
  passwordIcon: boolean;
  showLoginLoading: boolean;
  emailColor: string;
  passwordColor: string;
  emailErrorShow: string;
  passwordErrorShow: string;
  callResponseError: string;
  error: {
    emailError: string;
    passwordError: string;
  };
}
export class SuccessScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailErrorShow: "",
      passwordErrorShow: "",
      loginError: false,
      emailColor: "white",
      passwordColor: "white",
      callResponseError: '',
      passwordIcon: true,
      showLoginLoading: false,
      error: {
        emailError: "",
        passwordError: "",
      },
    };
  }
  componentDidMount() {

  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value, [key + 'Color']: 'white', [key + 'ErrorShow']: '' });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };
  async connect() {
    //@ts-ignore
    const eth = window.ethereum;

    // window.addEventListener('load', async () => {
    // Modern dapp browsers...
    if (eth) {
      try {
        // Request account access if needed
        //@ts-ignore

        // Acccounts now exposed
        // this.setState({ ui: "metamaskUI" })
        //@ts-ignore
        const acc = ethereum ? await ethereum.request({ method: 'eth_accounts' }) : [];
        console.log(acc)
        if (acc.length > 0) {
          window.localStorage.setItem('connectedAccount', acc[0]);

          if (window.location.search && window.location.search.toLocaleLowerCase().includes("returnurl")) {
            console.log(window.location.search.replace("?returnUrl=", ""));

            this.props.history.push(window.location.search.replace("?returnUrl=", ""));
          }
          else {
            //  this.props.history.push('');
            window.location.href = '/author'
          }
        }
        else {

          let redirect = '/wallet';

          if (window.location.search && window.location.search.toLocaleLowerCase().includes("returnurl")) {
            redirect = redirect + window.location.search;
          }

          // this.props.history.push(redirect);
          window.location.href = redirect
        }
        // window.location.reload();
      }

      catch (error) {
        //@ts-ignore
        window.alert(error.message)
        // User denied account access...
      }
    }
    else {
      // window.alert('Non-Ethereum browser detected. Please install MetaMask plugin');
      window.location.href = "https://metamask.io/download";
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
    // });

  }
  handleSubmit = async () => {
    console.log('handled')
    let { email, password, error } = this.state;
    let formError = Utils.constant.validateForm({ email, password, error });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {
      if (this.state.error.emailError != "" || this.state.error.passwordError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#ed2939", passwordColor: "#ed2939" })
        this.setState({
          emailErrorShow: this.state.error.emailError,
          passwordErrorShow: this.state.error.passwordError,
        })


      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "white", passwordColor: "white" })

      }
    });
    if (!isValid) {
      return false;
    } else {
      this.setState({ showLoginLoading: true });
      const token = await localStorage.getItem("fcmToken");
      let payload = {
        email,
        password,
        device_token: token,
        device_type: "3",
        user_type: Utils.constant.userType.recruiter,
        device_id: "device123",
        fcm_token: token,
      };
      this.props.doLogin(payload, (response: any) => {
        if (!response.loginerror && response.user_id) {
          this.setState(
            { email: "", password: "", showLoginLoading: false },
            () => {
              console.log(response)
              // this.props.history.push('/author')
              window.localStorage.setItem('user_id', response.user_id);
              window.localStorage.setItem('full_name', response.full_name);
              window.localStorage.setItem('profile_image', response.user_image);
              window.localStorage.setItem('twitter_user_name', response.twitter_user_name);
              this.connect();
            }
          );
        } else {
          console.log(response)
          this.setState({
            callResponseError: 'Invalid Username Or Password'
          })
          //@ts-ignore
          document.getElementById('errorMessage').style.display = 'block'
          setInterval(function () {
            //@ts-ignore
            document.getElementById('errorMessage').style.display = 'none'
          }, 5000);
          this.setState({
            showLoginLoading: false,
          });
        }
      });
    }
  };

  render() {
    return (
      <div id='card' className="animated fadeIn">
     <div id='upper-side'>
   <i className="fa fa-check"></i>
   <h3 id='status'> Success </h3> 
</div>
      <div id='lower-side'>
        <p id='message'>
          Congratulations, your account has been successfully created.
        </p>
        <a href="/login" id="contBtn">Continue</a>
      </div>
    </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    doLogin: (payload: any, callback: Function) =>
      dispatch(doLogin(payload, callback)),
  };
}

export const SuccessModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SuccessScreen)
);

export const Success = AuthLayout(SuccessModule);
