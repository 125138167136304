import React, { PureComponent } from "react";
import FaqCard from "../component/faq-card/faqcard";
import Authlayout from "../../../applayout/authlayout";
import TestimonialCard from "../component/testimonial-card/testimonialcard";
import RealTalentCard from "../component/realtalent-card/realtalentcard";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

interface Props {}

class LandingScreen extends PureComponent<Props> {
  render() {
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3, // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2, // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1, // optional, default to 1.
      },
    };
    return (
      <div>
     
        {/* Let us section close */}
      </div>
    );
  }
}
export const Landing = Authlayout(LandingScreen);
