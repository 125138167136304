import React, { useState, PureComponent, useEffect, memo } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { useTranslation, withTranslation } from "react-i18next";
import { ApolloProvider } from 'react-apollo';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { apolloClient } from "../../../apollo/client";
import Utils from "../../../utils";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha"
import { Textbox, Textarea } from 'react-inputs-validation';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { prepareForURL } from "../../../utils/common";
import { Environment } from "../../../enviroment";
// import './style2.css';
import en from '../../../languages/en/en.json'
import fr from '../../../languages/fr/fr.json'
import 'reactjs-popup/dist/index.css';
import axios from "axios";
const sgMail = require('@sendgrid/mail');
sgMail.setApiKey(process.env.SENDGRID_API_KEY);
const NAME_QUERY = gql`
query Testing{
    testings(locale: "fr"){
        data{
            id
            attributes{
                name
            }
        }
     
    }
  }`;

const POSTS_COUNT_QUERY = gql`
  query postsCount {
    postsCount(where: { Category: null })
  }`;

const Web3 = require('web3');
const multiplicationFactor = 0.20;
const constGasLimit = 250130;
const constGasPrice = 10000000000;

var checker = document.getElementById('Checkbox');
var sendbtn = document.getElementById('Signup');

interface Props {
  t?: any;
  history?: any;
}

interface State {
  lang: string;
  training: string;

  name: string;
  nameColor: string;
  nameErrorShow: string;

  username: string;
  usernameColor: string;
  usernameErrorShow: string;

  email: string;
  emailColor: string;
  emailErrorShow: string;

  message: string;
  formSubmitted: boolean;

  password: string;
  passwordColor: string;
  passwordErrorShow: string;

  passwordConfirm: string;
  passwordConfirmColor: string;
  passwordConfirmErrorShow: string;

  address: string;
  addressColor: string;
  addressErrorShow: string;

  telephone: string;
  telephoneColor: string;
  telephoneErrorShow: string;

  date: string;
  dateColor: string;
  dateErrorShow: string;

  diploma: string;
  diplomaColor: string;
  diplomaErrorShow: string;

  profession: string;
  professionColor: string;
  professionErrorShow: string;

  gender: string;
  genderColor: string;
  genderErrorShow: string;

  subject: string;
  captchaValue: string;
  isLoading: boolean;
  hasNameError: boolean;
  hasEmailError: boolean;
  hasMessageError: boolean;
  hasSubjectError: boolean;
  validate: boolean;
  errorMessage: string;
  checked: any;

  showSignupLoading: boolean;

  color: string,
  document: boolean;

  error: {
    // nameError: string;
    usernameError: string;
    passwordError: string;
    passwordConfirmError: string;
    emailError: string;
    telephoneError: string;
    // addressError: string;
    // dateError: string;
    // diplomaError: string;
    // professionError: string;
    // genderError: string;
  }
}

// const { t, i18n } = useTranslation();
// const [lang, setLang] = useState('en')
// useEffect(() => {
//     document.querySelectorAll('.lang_option')
//         .forEach(item => {
//             item.addEventListener('click', event => {
//                 if (item) {
//                     console.log('footer', item.innerHTML.toLocaleLowerCase())
//                     setLang(item.innerHTML.toLocaleLowerCase() == "english" ? "en" : "fr");
//                 }
//             });
//         })
// }, [])


export class RegisterScreen extends PureComponent<Props, State> {

  

  constructor(props: Props) {
    super(props);
    this.state = {
      lang: "en",
      training: "Training",

      
      name: "",
      nameColor: "white",
      nameErrorShow: "",

      email: "",
      emailColor: "white",
      emailErrorShow: "",

      message: "",
      formSubmitted: false,

      username: "",
      usernameColor: "white",
      usernameErrorShow: "",

      password: "",
      passwordColor: "white",
      passwordErrorShow: "",

      passwordConfirm: "",
      passwordConfirmColor: "white",
      passwordConfirmErrorShow: "",

      address: "",
      addressColor: "white",
      addressErrorShow: "",


      telephone: "",
      telephoneColor: "whiite",
      telephoneErrorShow: "",

      date: "2012-12-12",
      dateColor: "white",
      dateErrorShow: "",

      diploma: "",
      diplomaColor: "white",
      diplomaErrorShow: "",

      profession: "",
      professionColor: "white",
      professionErrorShow: "",

      gender: "",
      genderColor: "white",
      genderErrorShow: "",

      subject: "",
      captchaValue: "",
      isLoading: false,
      hasNameError: false,
      hasEmailError: false,
      hasMessageError: false,
      hasSubjectError: false,
      validate: false,
      showSignupLoading: false,
      errorMessage: "",
      checked: "off",
      document:false,

      color: "black",

      error: {
        // nameError: "",
        usernameError: "",
        emailError: "",
        passwordError: "",
        passwordConfirmError: "",
        telephoneError: "",
        // addressError: "",
        // dateError: "",
        // diplomaError: "",
        // professionError: "",
        // genderError: "",
      }
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);

  }
  onChange(value: any) {
    this.setState({ captchaValue: value });
    console.log("Captcha value:", value)
  }

  toggleValidating(validate: any) {
    this.setState({ validate });
  }


  async handleSubmit(e: any) {
    console.log('enter')
    e.preventDefault();
    this.toggleValidating(true);
    console.log('start')

    const
      {
        name,
        email,
        message,
        subject,
        captchaValue,
        hasNameError,
        hasEmailError,
        hasSubjectError,
        hasMessageError
      } = this.state;

    if (!hasNameError && !hasEmailError && !hasMessageError) {
      console.log('verified')
      const CONTACTUS_MUTATION = gql`mutation SendContactUsEmail($message:String!){
                createConstactus( data:{captcha:"${captchaValue}", subject:"MTM Web Contact Us Email", name:"${name}", email:"${email}", message:$message}){
                  data{
                      id
                      attributes{
                             email
                    name
                    message
                      }
                 
                  }
                }
              }`;


      this.setState({
        isLoading: true
      });

      try {
        console.log('in try')
        const result = await apolloClient.mutate({ mutation: CONTACTUS_MUTATION, variables: { message: message } });
        console.log('this is result', result)
        if (result) {
          this.setState({
            isLoading: false,
            formSubmitted: true
          });
        }

      } catch (ex) {

        this.setState({
          isLoading: false,
          errorMessage: `Error! ${ex}`
        });

      }
    }

    return false;
  }

  sendEmail(e: any) {
    console.log("hi")
    let message = {
      to: `${this.state.email}`,
      from: `${process.env.EMAIL_FORM1}`,
      // from:`zaeemmehmood@gmail.com`,
      subject: "Feed Back",
      html: `<p><strong>Name: </strong> ${this.state.name}</p>
<p><strong>Message: </strong> ${this.state.message}</p>
`
    }
    console.log("++++++++", message)
    sgMail.send(message).then(() => {
      console.log('sent')
    }).catch((err: any) => {
      console.log('this is error', err)
    })
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value, [key + 'Color']: 'white', [key + 'ErrorShow']: '' });

  };

  async doLogin(e:any){
  let formSession = Utils.constant.setSession({
    username: window.localStorage.getItem('username'),
    userId: window.localStorage.getItem('userId')
  })
  } 


  async Register(e: any) {

    let {
      username,
      // address,
      telephone,
      // date,
      // diploma,
      // gender,
      email,
      password,
      // profession,
      passwordConfirm,
      error,
    } = this.state;
    let formError = Utils.constant.validateForm({
      username,
      // address,
      telephone,
      // date,
      // diploma,
      // profession,
      // gender,
      email,
      password,
      passwordConfirm,
      error,
    });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {
      if (this.state.error.usernameError != "" || this.state.error.emailError != "" || this.state.error.telephoneError != "" || this.state.error.passwordError != "" || this.state.error.passwordConfirmError != ""  ) {
        console.log(this.state.error)
        if (this.state.error.usernameError != "") { this.setState({ usernameColor: "#ed2939" }) }
        if (this.state.error.emailError != "") { this.setState({ emailColor: "#ed2939" }) }
        if (this.state.error.passwordError != "") { this.setState({ passwordColor: "#ed2939" }) }
        if (this.state.error.passwordConfirmError != "") { this.setState({ passwordConfirmColor: "#ed2939" }) }
        if (this.state.error.telephoneError != "") { this.setState({ telephoneColor: "#ed2939" }) }
      
        this.setState({
          usernameErrorShow: this.state.error.usernameError,
          emailErrorShow: this.state.error.emailError,
          telephoneErrorShow: this.state.error.telephoneError,
          passwordErrorShow: this.state.error.passwordError,
          passwordConfirmErrorShow: this.state.error.passwordConfirmError,
     
        })
        this.setState({
          error: Object.assign({}, {
            nameError: "",
            usernameError: "",
            emailError: "",
            passwordError: "",
            passwordConfirmError: "",
            telephoneError: ""
          })
        })
      }
      else {
        console.log(this.state.error)
        this.setState({ usernameColor: "white", emailColor: "white", passwordColor: "white", passwordConfirmColor: "white", telephoneColor: "white", addressColor: "white", dateColor:"white", diplomaColor:"white", professionColor:"white", genderColor:"white" })
      }
    });

    if (!isValid) {
      return false;
    } else {
      this.setState({ showSignupLoading: true });
      axios
        .post('http://admin.parlercrypto.com/api/auth/local/register', {
          username: this.state.username,
          address: this.state.address,
          telephone: this.state.telephone,
          date: this.state.date,
          diploma: this.state.diploma,
          function: this.state.profession,
          gender: this.state.gender,
          email: this.state.email,
          password: this.state.password,
          passwordConfirm: this.state.passwordConfirm

        })
        .then((response) => {
          
          console.log(response);
          // Handle success.
          console.log('Well done!');
          console.log('User profile', response.data.user);
          console.log('User token', response.data.jwt);

          //@ts-ignore
         window.location.href = '/#/success';
        })
        .catch((error) => {
          console.log(error);
          // Handle error.
          console.log('An error occurred:', error.response.data.error.message); 
          if(error.response.data.error.message.includes("Email")){
            this.setState({errorMessage: error.response.data.error.message, emailErrorShow: error.response.data.error.message})
          }else{
            this.setState({errorMessage: error.response.data.error.message, usernameErrorShow: error.response.data.error.message})
            
          }
                  
          
        });
    }
  }




  async componentDidMount() {

    let femail = window.localStorage.getItem('femail');
    console.log(femail)
    if(femail){
      console.log('in if')
      this.setState({email: femail});
      window.localStorage.setItem('femail',"");
    }
    //@ts-ignore
    window.renderCarousal();
    let language = window.localStorage.getItem('lang')
    //@ts-ignore
    this.setState({ lang: language }, () => {
      //@ts-ignore
      window.renderCarousal();
    })
    document.querySelectorAll('.lang_option')
      .forEach(item => {
        item.addEventListener('click', event => {
          if (item) {
            console.log(item.innerHTML.toLocaleLowerCase())
            this.setState({ lang: item.innerHTML.toLocaleLowerCase() == "en" ? "en" : "fr" }, () => {

              //@ts-ignore
              window.renderCarousal();
            });

          }
        });
      })

  }


  render() {
 
    return (
      <>
        <ApolloProvider client={apolloClient}>
          <React.Fragment>


            {/* <section>
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <div className="">
                    <i className="fa fa-user" aria-hidden="true"></i>
                      <input type="text"
                        name="username"
                        id="username"
                        placeholder="Enter Username"
                        className="form-control"
                        onChange={(e: any) => {
                          this.handleFiledUpdate("username", e.currentTarget.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section> */}
            <section className="section6" style={{textAlign:"left"}}>

              <div className="container">

                <form action="/examples/actions/confirmation.php" method="post" name="myForm">

                  <div className="row">
                    <div className="col-lg-12 text-center">
                      <div className="section-title sec">
                        <h2>Sign Up</h2>
                        <p>Dig into a digital research platform to learn how cryptocurrency is shaping the future of economy. <br /> Sign Up and Start Learning Today!</p>

                        <p>Please fill in this form to create an account!</p>
                        {this.state.errorMessage? (<div className="alert alert-danger">{this.state.errorMessage }</div>):<></>}
                        <br />
                      </div>
                    </div>
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <div className="input-group">
                      {/* <span className="input-group-addon" id="inputGroup-sizing-sm"><i className="fa fa-user"></i></span> */}
                      <label htmlFor="" style={{color:this.state.usernameErrorShow!=""?"red":"black"}}>Username 
                      {/* <span className='errorr' style={{ color: "red", fontSize: "12px" }}> {this.state.usernameErrorShow}</span> */}
                      </label>
                      <span style={{color:"red"}}>{this.state.usernameErrorShow? " (The user name must be unique!)":""}</span>
                      <input type="text" className="form-control form1"
                        id="username"
                        name="username"
                        placeholder={this.state.usernameErrorShow!=""?this.state.usernameErrorShow:this.state.lang=="en"?en.op15:fr.op15}
                        required
                        value={this.state.username}
                        
                        onChange={(e: any) => {
                          this.handleFiledUpdate("username", e.currentTarget.value);
                        }}
                        style={{ borderColor: this.state.usernameColor}} />
                      {/* <span className='errorr' style={{ color: "red", fontSize: "12px" }}> {this.state.usernameErrorShow} </span> */}
                    </div>
                    
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <div className="input-group">
                      {/* <span className="input-group-addon"><i className="fa fa-location-arrow"></i></span> */}
                      <label htmlFor="" className="lab" >Address</label>
                      <input type="text" className="form-control form1"
                        id="address"
                        name="address"
                        placeholder={this.state.lang=="en"?en.op16:fr.op16}
                        
                        onChange={(e: any) => {
                          this.handleFiledUpdate("address", e.currentTarget.value);
                        }} 
                        style={{ borderColor: this.state.addressColor }}/>
                    </div>
                    {/* <div className='errorr' style={{ color: "red", fontSize: "12px" }}> {this.state.addressErrorShow} </div> */}
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <div className="input-group">
                      {/* <span className="input-group-addon"><i className="fa fa-phone"></i></span> */}
                      <label htmlFor="" className="lab" style={{color:this.state.telephoneErrorShow!=""?"red":"black"}}>Telephone
                      {/* <span className='errorr' style={{ color: "red", fontSize: "12px"}}> {this.state.telephoneErrorShow} </span> */}
                      </label>
                      <input type="tel" className="form-control form1"
                        id="telephone"
                        name="telephone"
                        placeholder={this.state.telephoneErrorShow!=""?this.state.telephoneErrorShow:this.state.lang=="en"?en.op17:fr.op17}
                        required
                        onChange={(e: any) => {
                          this.handleFiledUpdate("telephone", e.currentTarget.value);
                        }}
                        style={{ borderColor: this.state.telephoneColor }}
                      />

                    </div>
                    
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <div className="input-group">
                      {/* <span className="input-group-addon"><i className="fa fa-calendar"></i></span> */}
                      <label htmlFor="" className="lab">Date of Birth</label>
                      <input type="date" className="form-control form1"
                        id="date"
                        name="date"
                        value={this.state.date}
                        placeholder={this.state.lang=="en"?en.op18:fr.op18}
                        // required
                        onChange={(e: any) => {
                          this.handleFiledUpdate("date", e.currentTarget.value);
                          console.log("selected date", e.target.value)
                        }} 
                        style={{ borderColor: this.state.dateColor }}/>
                    </div>
                    {/* <div className='errorr' style={{ color: "red", fontSize: "12px"}}> {this.state.dateErrorShow} </div> */}
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <div className="input-group">
                      {/* <span className="input-group-addon"><i className="fa fa-graduation-cap"></i></span> */}
                      <label htmlFor="" className="lab">Qualification</label>
                      <input type="text" className="form-control form1"
                        id="diploma"
                        name="diploma"
                        placeholder={this.state.lang=="en"?en.op19:fr.op19}
                        // required
                        onChange={(e: any) => {
                          this.handleFiledUpdate("diploma", e.currentTarget.value);
                        }} 
                        style={{ borderColor: this.state.diplomaColor }}/>
                    </div>
                    {/* <div className='errorr' style={{ color: "red", fontSize: "12px"}}> {this.state.diplomaErrorShow} </div> */}
                  </div>

                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                  <label htmlFor="" className="lab">Profession</label>
                    <div className="input-group" style={{backgroundColor:"white", color:"white"}}>
                      {/* <span className="input-group-addon"><i className="fa fa-briefcase"></i></span> */}
                      <select className="form-control" id="profession" name="profession" placeholder={this.state.lang=="en"?en.op20:fr.op20}  onChange={(e: any) => {
                        this.handleFiledUpdate("profession", e.currentTarget.value);
                      }} 
                      style={{ borderColor: this.state.professionColor }}>
                         <option selected>
                          Function
                        </option>
                        <option value="employé">
                          Employé
                        </option>
                        <option value="Cadre">
                          Cadre
                        </option>
                        <option value="Directeur">
                          Directeur
                        </option>
                        <option value="Chef d’entreprise">
                          Chef d’entreprise
                        </option>
                        <option value="Entrepreneur">
                          Entrepreneur
                        </option>
                        <option value=" Autres">
                          Autres
                        </option>
                      </select>
                    </div>
                    {/* <div className='errorr' style={{ color: "red", fontSize: "12px"}}> {this.state.professionErrorShow} </div> */}
                  </div>

                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <label htmlFor="" className="lab">Sexe</label>
                    <div className="input-group " style={{backgroundColor:"white", color:"white"}}>
                      {/* <span className="input-group-addon"><i className="fa fa-users"></i></span> */}
                      <select className="form-control" id="gender" name="gender" onChange={(e: any) => {
                        this.handleFiledUpdate("gender", e.currentTarget.value);
                      }} 
                      style={{ borderColor: this.state.genderColor}}
                      >
                          <option selected>
                          Sexe
                        </option>
                        <option value="Male">
                          Male
                        </option>
                        <option value="Female">
                          Female
                        </option>
                      </select>
                    </div>
                    {/* <div className='errorr' style={{ color: "red", fontSize: "12px"}}> {this.state.genderErrorShow} </div> */}
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <div className="input-group">
                      {/* <span className="input-group-addon"><i className="fa fa-envelope"></i></span> */}
                      <label htmlFor="" className="lab" style={{color:this.state.emailErrorShow!=""?"red":"black"}}>Email
                      {/* <span className='errorr' style={{ color: "red", fontSize: "12px" }}> {this.state.emailErrorShow} </span> */}
                      </label>
                      <input type="email"
                        className="form-control form1"
                        name="email"
                        placeholder={this.state.emailErrorShow!=""?this.state.emailErrorShow:this.state.lang=="en"?en.op22:fr.op22}
                        required
                        id="email"
                        value={this.state.email}
                        onChange={(e: any) => {
                          this.handleFiledUpdate("email", e.currentTarget.value);
                        }}
                        style={{ borderColor: this.state.emailColor }} />

                    </div>
                    
                  </div>

                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <div className="input-group">
                      {/* <span className="input-group-addon"><i className="fa fa-lock"></i></span> */}
                      <label htmlFor="" className="lab" style={{color:this.state.passwordErrorShow!=""?"red":"black"}}>Password
                      {/* <span className='errorr' style={{ color: "red", fontSize: "12px" }}> {this.state.passwordErrorShow} </span> */}
                      </label>
                      <input type="password"
                        className="form-control form1"
                        name="password"
                        placeholder={this.state.passwordErrorShow!=""?this.state.passwordErrorShow: this.state.lang=="en"?en.op23:fr.op23}
                        required
                        id="password"
                        onChange={(e: any) => {
                          this.handleFiledUpdate("password", e.currentTarget.value);
                        }}
                        style={{ borderColor: this.state.passwordColor }} />

                    </div>
                    
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <div className="input-group">
                      {/* <span className="input-group-addon">
                        <i className="fa fa-lock"></i>
                      </span> */}
                      <label htmlFor="" className="lab" style={{color:this.state.passwordConfirmErrorShow!=""?"red":"black"}}>Confirm Password
                      {/* <span className='errorr' style={{ color: "red", fontSize: "12px" }}> {this.state.passwordConfirmErrorShow} </span> */}
                      </label>
                      <input type="password"
                        className="form-control form1"
                        name="passwordConfirm"
                        placeholder={this.state.passwordConfirmErrorShow!=""?this.state.passwordConfirmErrorShow: this.state.lang=="en"?en.op24:fr.op24}
                        
                        required
                        onChange={(e: any) => {
                          this.handleFiledUpdate("passwordConfirm", e.currentTarget.value);
                        }}
                        style={{ borderColor: this.state.passwordConfirmColor }} />

                    </div>
                    
                  </div>
                  <div className="form-group col-md-6 col-sm-6 col-lg-6">
                    <label style={{fontSize:"13px"}} className="manual2"><input type="checkbox" value={this.state.checked}
                    onChange={(e:any) => {
                      console.log("++", e.target.value)
                      console.log("++", e.target.checked)
                      if(e.target.checked==true){this.setState({checked:"on"})}
                      else{this.setState({checked:"off"})}
                    }}
                    /> I accept the <a href="#">Terms of Use</a> &amp; <a href="#" style={{wordWrap:"break-word"}}>Privacy Policy</a> <span className="checkmark"></span> </label>
                  </div>
                  <div className="form-group col-lg-12">
                    <button type="button"
                      className="btn"
                      name="Signup"
                    disabled={this.state.checked=="on"?false:true}
                      id="Signup"
                      onClick={(e: any) => {
                     this.state.checked=="on"?this.Register(e):console.log('disabled');
                      }}>Sign Up</button>
                  </div>
                  <hr />
                  <div className="text-center col-lg-12 acnt">Already have an account? <a style={{ color: "black" }} href="/#/login">Login here</a></div>
                </form>

              </div>
            </section>

          </React.Fragment>
        </ApolloProvider>
      </>
    );
  }
}

function mapStateToProps(state: any) {

  return {
    
  };
}

function mapDispatchToProps(dispatch: Function) {
  return {
  }
}

export const RegisterModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RegisterScreen)
);

export const Register = AppLayout(RegisterModule);
 