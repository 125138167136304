import React, { PureComponent } from "react";
import OnBoardingBanner from "../component/onboardingleft/onboardingbanner";

import Authlayout from "../../../applayout/authlayout";
import Utils from "../../../utils";
interface Props {}

interface State {}

class ResetSuccessScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <section>
        <div className="custom_container">
          <div className="f-row onboarding_wrapper">
            <OnBoardingBanner pageName={"reset sucessfully"}></OnBoardingBanner>
            <div className="onboarding_col_right">
              <figure className="side_circle"></figure>
              <div className="inner_wrapper">
                <div className="form_wrapper">
                  <div className="success_wrap text-center">
                    <figure className="congo_icon_wrap">
                      {/* <img
                        src={require("../../../assets/images/ic_password change.svg")}
                      /> */}
                    </figure>
                    <h1>Congratulations!</h1>
                    <p>You have successfully changed your password</p>
                    <div className="form_filed_wraper m-t-20">
                      <button className="primar_btn btn_effect">OK</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export const ResetSuccess = Authlayout(ResetSuccessScreen);
