import React, { PureComponent } from "react";
import { connect } from "react-redux";

import AuthLayout from "../../../applayout/authlayout";
import { doLogin } from "..";
import { withTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
import { Link } from 'react-router-dom';
interface Props {
  t: any;
  history?: any;
  doLogin: Function;
  loginResponce?: any;
}
interface State {
  email: string;
  password: string;
  loginError: boolean;
  passwordIcon: boolean;
  showLoginLoading: boolean;
  emailColor: string;
  passwordColor: string;
  emailErrorShow: string;
  passwordErrorShow: string;
  callResponseError: string;
  error: {
    emailError: string;
    passwordError: string;
  };
}
export class SigninScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailErrorShow: "",
      passwordErrorShow: "",
      loginError: false,
      emailColor: "white",
      passwordColor: "white",
      callResponseError: '',
      passwordIcon: true,
      showLoginLoading: false,
      error: {
        emailError: "",
        passwordError: "",
      },
    };
  }
  componentDidMount() {

  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value, [key + 'Color']: 'white', [key + 'ErrorShow']: '' });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };
  async connect() {
    //@ts-ignore
    const eth = window.ethereum;

    // window.addEventListener('load', async () => {
    // Modern dapp browsers...
    if (eth) {
      try {
        // Request account access if needed
        //@ts-ignore

        // Acccounts now exposed
        // this.setState({ ui: "metamaskUI" })
        //@ts-ignore
        const acc = ethereum ? await ethereum.request({ method: 'eth_accounts' }) : [];
        console.log(acc)
        if (acc.length > 0) {
          window.localStorage.setItem('connectedAccount', acc[0]);

          if (window.location.search && window.location.search.toLocaleLowerCase().includes("returnurl")) {
            console.log(window.location.search.replace("?returnUrl=", ""));

            this.props.history.push(window.location.search.replace("?returnUrl=", ""));
          }
          else {
            //  this.props.history.push('');
            window.location.href = '/author'
          }
        }
        else {

          let redirect = '/wallet';

          if (window.location.search && window.location.search.toLocaleLowerCase().includes("returnurl")) {
            redirect = redirect + window.location.search;
          }

          // this.props.history.push(redirect);
          window.location.href = redirect
        }
        // window.location.reload();
      }

      catch (error) {
        //@ts-ignore
        window.alert(error.message)
        // User denied account access...
      }
    }
    else {
      // window.alert('Non-Ethereum browser detected. Please install MetaMask plugin');
      window.location.href = "https://metamask.io/download";
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
    // });

  }
  handleSubmit = async () => {
    console.log('handled')
    let { email, password, error } = this.state;
    let formError = Utils.constant.validateForm({ email, password, error });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {
      if (this.state.error.emailError != "" || this.state.error.passwordError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#ed2939", passwordColor: "#ed2939" })
        this.setState({
          emailErrorShow: this.state.error.emailError,
          passwordErrorShow: this.state.error.passwordError,
        })


      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "white", passwordColor: "white" })

      }
    });
    if (!isValid) {
      return false;
    } else {
      this.setState({ showLoginLoading: true });
      const token = await localStorage.getItem("fcmToken");
      let payload = {
        email,
        password,
        device_token: token,
        device_type: "3",
        user_type: Utils.constant.userType.recruiter,
        device_id: "device123",
        fcm_token: token,
      };
      this.props.doLogin(payload, (response: any) => {
        if (!response.loginerror && response.user_id) {
          this.setState(
            { email: "", password: "", showLoginLoading: false },
            () => {
              console.log(response)
              // this.props.history.push('/author')
              window.localStorage.setItem('user_id', response.user_id);
              window.localStorage.setItem('full_name', response.full_name);
              window.localStorage.setItem('profile_image', response.user_image);
              window.localStorage.setItem('twitter_user_name', response.twitter_user_name);
              this.connect();
            }
          );
        } else {
          console.log(response)
          this.setState({
            callResponseError: 'Invalid Username Or Password'
          })
          //@ts-ignore
          document.getElementById('errorMessage').style.display = 'block'
          setInterval(function () {
            //@ts-ignore
            document.getElementById('errorMessage').style.display = 'none'
          }, 5000);
          this.setState({
            showLoginLoading: false,
          });
        }
      });
    }
  };

  render() {
    return (
      <div id="wrapper">
        <div className="no-bottom no-top" id="content">
          <div id="top"></div>

          <section className="full-height relative no-top no-bottom vertical-center bglogin" data-bgimage="url(images/background/subheader.jpg) top" data-stellar-background-ratio=".5">
            <div className="overlay-gradient t50">
              <div className="center-y relative">
                <div className="container">
                  <div className="row align-items-center">
                    <div className="col-lg-5 text-light wow fadeInRight" data-wow-delay=".5s">
                      <div className="spacer-10"></div>
                      <h1>Create, sell or collect digital items.</h1>
                    </div>

                    <div className="col-lg-4 offset-lg-2 wow fadeIn" data-wow-delay=".5s">
                      <div className="box-rounded padding40" data-bgcolor="#ffffff">
                        <h3 className="mb10">Sign In</h3>
                        <p>Login using an existing account or create a new account <a href="/signup">here<span></span></a>.</p>
                        <form name="contactForm" id='contact_form' className="form-border" method="post" action='blank.php'>

                          <div className="field-set">
                            <div className="alert alert-danger" id='errorMessage' style={{ display: 'none' }} role="alert">
                              {this.state.callResponseError}
                            </div>
                            <div className='errorr'>{this.state.emailErrorShow}</div>
                            <input
                              style={{ background: this.state.emailColor }}
                              value={this.state.email}
                              onChange={(e: any) => {
                                this.handleFiledUpdate("email", e.currentTarget.value);
                              }}
                              type='text' name='email' id='email' className="form-control" placeholder="E-mail Address" />
                          </div>

                          <div className="field-set">
                            <div className='errorr'>{this.state.passwordErrorShow}</div>
                            <input
                              style={{ background: this.state.passwordColor }}
                              value={this.state.password}
                              onChange={(e: any) => {
                                this.handleFiledUpdate("password", e.currentTarget.value);
                              }}
                              onKeyDown={this.handleKeyDown}
                              type='password' name='password' id='password' className="form-control" placeholder="password" />
                          </div>

                          <div className="field-set">
                            <input
                              onClick={this.handleSubmit}
                              type='button' id='send_message' value='Submit' className="btn btn-main btn-fullwidth color-2" />

                          </div>
                          <div className="forgot-password"><Link to="/forgot" className="forgot-password-span">Forgot password</Link></div>
                          <div className="clearfix"></div>

                          <div className="spacer-single"></div>


                          {/* <ul className="list s3">
                            <li>Login with:</li>
                            <li><a href="#">Facebook</a></li>
                            <li><a href="#">Google</a></li>
                          </ul> */}

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>

      </div>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    doLogin: (payload: any, callback: Function) =>
      dispatch(doLogin(payload, callback)),
  };
}

export const SigninModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SigninScreen)
);

export const Signin = AuthLayout(SigninModule);
