import React, { PureComponent } from "react";
import OnBoardingBanner from "../component/onboardingleft/onboardingbanner";
import i18n from "i18next";
import Authlayout from "../../../applayout/authlayout";
import { NavLink, useHistory } from "react-router-dom";
import Utils from "../../../utils";
import { doSignup } from "../";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
//import firebase from "../../../utils/firebase";
import utils from "../../../utils";

interface Props {
  t: any;
  doSignup: Function;
  history?: any;
  signUpResponce?: any;
}

interface State {
  name: string;
  nameColor: string;
  // lastname: string;
  email: string;
  password: string;
  referralCode: string;
  passwordIcon: boolean;
  phone: string;
  form: string;
  country: string;
  phoneColor: string;
  countryColor: string;
  username: string;
  usernameColor: string;
  emailColor: string;
  color: string,
  passwordConfirm: string;
  passwordColor: string;
  passwordConfirmColor: string
  showSignupLoading: boolean;
  nameErrorShow: string;
  emailErrorShow: string;
  usernameErrorShow: string;
  passwordErrorShow: string;
  phoneErrorShow: string;
  countryErrorShow: string;
  passwordConfirmErrorShow: string;
  apiError: string;

  error: {
    emailError: string;
    passwordError: string;
    nameError: string;
    usernameError: string;
    phoneError: string;
    countryError: string;
    passwordConfirmError: string;
  };
}
class SignupScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: "",
      nameColor: "white",
      username: "",
      usernameColor: "white",
      phone: "",
      country: "",
      phoneColor: "white",
      countryColor: "white",
      form:"",
      email: "",
      password: "",
      passwordConfirm: '',
      referralCode: "",
      passwordIcon: true,
      emailColor: "white",
      passwordColor: "white",
      passwordConfirmColor: "white",
      color: "black",
      showSignupLoading: false,
      nameErrorShow: "",
      emailErrorShow: "",
      usernameErrorShow: "",
      passwordErrorShow: "",
      phoneErrorShow: "",
      countryErrorShow: "",
      passwordConfirmErrorShow: "",
      apiError: "",

      error: {
        emailError: "",
        nameError: "",
        usernameError: "",
        passwordError: "",
        phoneError: "",
        countryError: "",
        passwordConfirmError: "",
      },
    };
  }

   SignUp = () => {
    // const [form] = Form.useForm();
console.log("hey");
    const signUpSuccessUrl = "/SignIn";
    const history = useHistory();
    function toSignIn() {
      history.push(signUpSuccessUrl);
    }

    const onFinish = (values: { email: any; password: any; username: any; }) => {
      console.log('Received values of form: ', values);
      const graphqlQuery = {
        query: `
        mutation register($email: String!, $username: String!, $password: String!) {
          register(input: {email: $email, username: $username, password: $password}) {
                 user {id, username, email}, jwt
          }
     }
        `,
        variables: {
          email: values.email,
          password: values.password,
          username: values.username
        }
      };
      fetch('http://localhost:1337/register', {
        method: 'POST',
        headers:{
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(graphqlQuery)
      })
      .then(res => {
        return res.json();
      })
      .then (resDate => {
        if (resDate.errors && resDate.errors[0].status == 422) {
          throw new Error(
            "Validation Failed"
          );
        }
        if(resDate.errors){
          throw new Error('User Creation Failed!');
        }
        console.log(resDate);
        alert("User with Username "+ resDate.data.register.user.username + "is created");
        toSignIn();
      })
      .catch(err => {
        console.log(err);
      })
    }
  }

  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value, [key + 'Color']: 'white', [key + 'ErrorShow']: '' });

  };
  selectLang = (language: string) => {
    Utils.constant.axios.defaults.headers.lng = language;
    i18n.changeLanguage(language);
    console.log(Utils.constant.axios.defaults.headers.lng)
  };
  handleSubmit = async () => {
    let {
      name,
      username,
      email,
      password,
      phone,
      country,
      error
    } = this.state;
    let formError = Utils.constant.validateForm({
      name,
      username,
      email,
      password,
      country,
      error,
      phone
    });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {
      if (this.state.error.emailError != "" || this.state.error.passwordError != "" || this.state.error.passwordConfirmError != "" || this.state.error.usernameError != "" || this.state.error.nameError != "" || this.state.error.phoneError != "" || this.state.error.countryError != "") {
        console.log(this.state.error)
        if (this.state.error.emailError != "") { this.setState({ emailColor: "#ed2939" }) }
        if (this.state.error.passwordError != "") { this.setState({ passwordColor: "#ed2939" }) }
        if (this.state.error.passwordConfirmError != "") { this.setState({ passwordConfirmColor: "#ed2939" }) }
        if (this.state.error.phoneError != "") { this.setState({ phoneColor: "#ed2939" }) }
        if (this.state.error.countryError != "") { this.setState({ countryColor: "#ed2939" }) }
        if (this.state.error.usernameError != "") { this.setState({ usernameColor: "#ed2939" }) }
        if (this.state.error.nameError != "") { this.setState({ nameColor: "#ed2939" }) }
        this.setState({
          nameErrorShow: this.state.error.nameError,
          emailErrorShow: this.state.error.emailError,
          usernameErrorShow: this.state.error.usernameError,
          passwordErrorShow: this.state.error.passwordError,
          phoneErrorShow: this.state.error.phoneError,
          countryErrorShow: this.state.error.countryError,
          passwordConfirmErrorShow: this.state.error.passwordConfirmError

        })
        this.setState({
          error: Object.assign({}, {
            emailError: "",
            nameError: "",
            usernameError: "",
            passwordError: "",
            phoneError: "",
            countryError: "",
            passwordConfirmError: "",
          })
        })
      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "white", passwordColor: "white", passwordConfirmColor: "white", phoneColor: "white", usernameColor: "white", nameColor: "white", countryColor: "white" })

      }
    });
    if (!isValid) {
      return false;
    } else {
      this.setState({ showSignupLoading: true });
      let payload = {
        email,
        password,
        name: name,
        username: username,
        phone: phone,
        country: country
      };
      let tokens = {
        fcmToken: localStorage.getItem("fcmToken"),
      };
      console.log('this is payload')
      console.log(payload);
      if (this.state.password == this.state.passwordConfirm) {
        this.setState({ color: "black" })
        this.props.doSignup(payload, tokens, (response: any) => {
          console.log('back')
          console.log(response);
          if (response.user_id) {
            console.log('it came back though')
            this.setState(
              {
                name: " ",
                username: "",
                email: "",
                password: "",
                phone: "",
                country: "",

                showSignupLoading: false,
              },
              () => {
                console.log('going in')
                // this.props.history.push("/author");
                console.log(response);
                //setting the item for retrieving
                localStorage.setItem("full_name", name);
                localStorage.setItem("username", username);
                this.connect();

              }
            );
          } else {
            this.setState({ showSignupLoading: false });

            this.setState({ apiError: response?.data?.message });
            console.log(response);
          }
        });
      }
      else {
        this.setState({ color: "#AF0404" })
      }

    }
  };
  async connect() {
    //@ts-ignore
    const eth = window.ethereum;

    // window.addEventListener('load', async () => {
    // Modern dapp browsers...
    if (eth) {
      try {
        // Request account access if needed
        //@ts-ignore

        // Acccounts now exposed
        // this.setState({ ui: "metamaskUI" })
        //@ts-ignore
        const acc = ethereum ? await ethereum.request({ method: 'eth_accounts' }) : [];

        if (acc.length > 0) {
          window.localStorage.setItem('connectedAccount', acc[0]);

          if (window.location.search && window.location.search.toLocaleLowerCase().includes("returnurl")) {
            console.log(window.location.search.replace("?returnUrl=", ""));

            this.props.history.push(window.location.search.replace("?returnUrl=", ""));
          }
          else {
            //this.props.history.push('/author');
            window.location.href = window.location.href = '/wallet?returnUrl=/author';
          }
        }
        else {

          let redirect = '/wallet?returnUrl=/author';

          if (window.location.search && window.location.search.toLocaleLowerCase().includes("returnurl")) {
            redirect = redirect + window.location.search;
          }

          //this.props.history.push();
          window.location.href = redirect
        }
        // window.location.reload();
      }

      catch (error) {
        //@ts-ignore
        window.alert(error.message)
        // User denied account access...
      }
    }
    else {
      // window.alert('Non-Ethereum browser detected. Please install MetaMask plugin');
      window.location.href = "https://metamask.io/download";
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
    // });

  }
  componentDidMount() {
    // if (Utils.firebase.messaging.isSupported()) {
    //   const fcm_token = Utils.firebase.messaging();
    //   fcm_token
    //     .requestPermission()
    //     .then((token) => {
    //       return fcm_token.getToken();
    //     })
    //     .then((save_token) => {
    //       localStorage.setItem("fcmToken", save_token);
    //     })
    //     .catch((err) => { });
    // }
  }



  render() {
    const { t } = this.props;
    return (
      <div id='wrapper'>
        <div className="no-bottom no-top" id="content">
          <div id="top"></div>

          <section id="subheader" className="text-light bglogin" data-bgimage="url(images/background/subheader.jpg) top">
            <div className="center-y relative text-center">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 text-center">
                    <h1>Sign Up</h1>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </section>
          <section aria-label="section">
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <h3>Don't have an account? Sign Up now.</h3>
                  <p>Already have an account? <a href="/signin">Sign In</a></p>
                  <p>All fields are mandatory.</p>
                  <div className="spacer-10"></div>
                  <form name="contactForm" id='contact_form' className="form-border" method="post">
                    {this.state.apiError && <div className="row">

                      <div className="col-md-12">
                        <div className="alert alert-danger">
                          {this.state.apiError}
                        </div>
                      </div>

                    </div>}



                    <div className="row">

                      {/* <div className="col-md-6">
                        <div className="field-set">

                          <label>Full name (will not be displayed)</label>
                          <div className='errorr'>{this.state.nameErrorShow}</div>
                          <input
                            style={{ backgroundColor: this.state.nameColor }}
                            value={this.state.name}
                            onChange={(e: any) => {
                              this.handleFiledUpdate("name", e.currentTarget.value);
                            }}
                            type='text' name='name' id='name' className="form-control"
                            placeholder="John Doe"
                          />

                        </div>
                      </div> */}

                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Email address</label>
                          <div className='errorr'>{this.state.emailErrorShow}</div>
                          <input
                            style={{ backgroundColor: this.state.emailColor }}
                            value={this.state.email}
                            onChange={(e: any) => {
                              this.handleFiledUpdate("email", e.currentTarget.value);
                            }}
                            type='text' name='email' id='email' className="form-control" />

                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Username (Only letters, numbers and underscores)</label>
                          <div className='errorr'>{this.state.usernameErrorShow}</div>
                          {/* <p className="label-message"></p> */}
                          <input
                            style={{ backgroundColor: this.state.usernameColor }}
                            value={this.state.username}
                            onChange={(e: any) => {
                              this.handleFiledUpdate("username", e.currentTarget.value);
                            }}
                            type='text' name='username' id='username' className="form-control"
                            placeholder="TheArtist"
                            minLength={3}
                            maxLength={20}
                            pattern="[a-zA-Z0-9]+"
                          />

                        </div>
                      </div>

                      {/* <div className="col-md-6">
                        <div className="field-set">
                          <label>Phone</label>
                          <div className='errorr'>{this.state.phoneErrorShow}</div>
                          <input
                            style={{ backgroundColor: this.state.phoneColor }}
                            value={this.state.phone}
                            onChange={(e: any) => {
                              this.handleFiledUpdate("phone", e.currentTarget.value);
                            }}
                            type='text' name='phone' id='phone' className="form-control" />

                        </div>
                      </div> */}
                      <div className="col-md-6">
                        <div className="field-set">
                          <label>Password</label>
                          <div className='errorr'>{this.state.passwordErrorShow}</div>
                          <input
                            style={{ backgroundColor: this.state.passwordColor, color: this.state.color }}
                            value={this.state.password}
                            onChange={(e: any) => {
                              this.handleFiledUpdate("password", e.currentTarget.value);
                            }}
                            type='password' name='password' id='password' className="form-control" />
                          {this.state.passwordErrorShow}
                        </div>
                      </div>
                      {/* <div className="col-md-6">
                        <div className="field-set">
                          <label>Re-enter Password</label>
                          <div className='errorr'>{this.state.passwordConfirmErrorShow}</div>
                          <input
                            value={this.state.passwordConfirm}
                            onChange={(e: any) => {
                              this.handleFiledUpdate("passwordConfirm", e.currentTarget.value);
                            }}
                            style={{ backgroundColor: this.state.passwordConfirmColor, color: this.state.color }}
                            type='password' name='re-password' id='re-password' className="form-control" />

                        </div>
                      </div> */}



                      {/* <div className="col-md-6">
                        <div className="field-set">
                          <label>Country</label>
                          <div className='errorr'>{this.state.countryErrorShow}</div> */}
                          {/* <input
                            style={{ backgroundColor: this.state.countryColor }}
                            value={this.state.country}
                            onChange={(e: any) => {
                              this.handleFiledUpdate("country", e.currentTarget.value);
                            }}
                            type='text' name='country' id='country' className="form-control" /> */}

                          {/* <select id="country" name="country" onChange={(e: any) => {
                            this.handleFiledUpdate("country", e.currentTarget.value);
                          }} value={this.state.country} style={{ backgroundColor: this.state.countryColor }} className="form-control">
                            <option value="Afganistan">Afghanistan</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bonaire">Bonaire</option>
                            <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                            <option value="Brunei">Brunei</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Canary Islands">Canary Islands</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Channel Islands">Channel Islands</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos Island">Cocos Island</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote DIvoire">Cote DIvoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Curaco">Curacao</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="East Timor">East Timor</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands">Falkland Islands</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Ter">French Southern Ter</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Great Britain">Great Britain</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Hawaii">Hawaii</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="India">India</option>
                            <option value="Iran">Iran</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea North">Korea North</option>
                            <option value="Korea Sout">Korea South</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Laos">Laos</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libya">Libya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macau">Macau</option>
                            <option value="Macedonia">Macedonia</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Midway Islands">Midway Islands</option>
                            <option value="Moldova">Moldova</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Nambia">Nambia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherland Antilles">Netherland Antilles</option>
                            <option value="Netherlands">Netherlands (Holland, Europe)</option>
                            <option value="Nevis">Nevis</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau Island">Palau Island</option>
                            <option value="Palestine">Palestine</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Phillipines">Philippines</option>
                            <option value="Pitcairn Island">Pitcairn Island</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Republic of Montenegro">Republic of Montenegro</option>
                            <option value="Republic of Serbia">Republic of Serbia</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russia">Russia</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="St Barthelemy">St Barthelemy</option>
                            <option value="St Eustatius">St Eustatius</option>
                            <option value="St Helena">St Helena</option>
                            <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                            <option value="St Lucia">St Lucia</option>
                            <option value="St Maarten">St Maarten</option>
                            <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                            <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                            <option value="Saipan">Saipan</option>
                            <option value="Samoa">Samoa</option>
                            <option value="Samoa American">Samoa American</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syria">Syria</option>
                            <option value="Tahiti">Tahiti</option>
                            <option value="Taiwan">Taiwan</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania">Tanzania</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Erimates">United Arab Emirates</option>
                            <option value="United States of America">United States of America</option>
                            <option value="Uraguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Vatican City State">Vatican City State</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Vietnam">Vietnam</option>
                            <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                            <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                            <option value="Wake Island">Wake Island</option>
                            <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zaire">Zaire</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select> */}

                        {/* </div> */}
                      {/* </div> */}



                      <div className="col-md-12">

                        <div id='submit' className="pull-left">
                          <input
                            onClick={this.SignUp}
                            type='button' id='send_message_submit' value='Sign Up' className="btn btn-main color-2" />
                        </div>
                        <div id='mail_success' className='success'>Your message has been sent successfully.</div>
                        <div id='mail_fail' className='error'>Sorry, error occured this time sending your message.</div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
        <a href="#" id="back-to-top"></a>
      </div>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    signUpResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    doSignup: (payload: any, tokens: any, callback: Function) =>
      dispatch(doSignup(payload, tokens, callback)),
  };
}

const SignupModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SignupScreen)
);

export const Signup = Authlayout(SignupModule);
